import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-provider-view1",
  templateUrl: "./provider-view1.component.html",
  styleUrls: ["./provider-view1.component.scss"],
})
export class ProviderView1Component implements OnInit {
  @Input() provider;

  constructor() {}

  ngOnInit() {}
}
