import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NotifierService } from "angular-notifier";

import { AuthService } from "../../../_services/auth.service";

@Component({
  selector: "app-remind-password",
  templateUrl: "./remind-password.component.html",
  styleUrls: ["./remind-password.component.scss"],
})
export class RemindPasswordComponent implements OnInit {
  remindForm: FormGroup = null;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.remindForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  get remindF() {
    return this.remindForm.controls;
  }

  remind() {
    this.submitted = true;

    if (this.remindForm.valid) {
      var req = {
        email: this.remindF["email"].value,
      };

      this.authService.remindPassword(req).subscribe(
        (res) => {
          this.notifierService.notify(
            "success",
            "Password reset link sent success."
          );
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    }
  }
}
