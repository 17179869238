import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-coming-soon-account",
  templateUrl: "./coming-soon-account.component.html",
  styleUrls: ["./coming-soon-account.component.scss"],
})
export class ComingSoonAccountComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
