import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-category-listing2",
  templateUrl: "./category-listing2.component.html",
  styleUrls: ["./category-listing2.component.scss"],
})
export class CategoryListing2Component implements OnInit {
  @Input() categorys;
  @Output("selectE") selectE: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  select(event) {
    this.selectE.emit(event);
  }
}
