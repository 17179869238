import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-category-listing4",
  templateUrl: "./category-listing4.component.html",
  styleUrls: ["./category-listing4.component.scss"],
})
export class CategoryListing4Component implements OnInit {
  @Input() rProducts;

  constructor() {}

  ngOnInit() {}
}
