import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { AuthService } from "../../../_services/auth.service";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      var req = {
        token: params["token"],
      };

      this.authService.verifyEmail(req).subscribe(
        (res) => {
          this.notifierService.notify(
            "success",
            "Your account was verified successfully."
          );

          setTimeout(() => {
            this.router.navigateByUrl("/login");
          }, 1000);
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    });
  }
}
