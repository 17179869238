import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category-view3",
  templateUrl: "./category-view3.component.html",
  styleUrls: ["./category-view3.component.scss"],
})
export class CategoryView3Component implements OnInit {
  @Input() category;

  constructor(private router: Router) {}

  ngOnInit() {}

  action() {
    if (!this.category.action_type.is_content)
      this.router.navigateByUrl(
        `/portal-category/view/${this.category.action_url}`
      );
    else {
      if (this.category.action_type.is_image)
        this.router.navigateByUrl(
          `/portal-category/super/${this.category.action_url}`
        );
      else
        this.router.navigateByUrl(
          `/portal-category/simple/${this.category.action_url}`
        );
    }
  }
}
