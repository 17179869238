import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "money",
})
export class MoneyPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      var negative = false;

      if (value < 0) {
        negative = true;
        value = Math.abs(value);
      }

      var parts,
        part1,
        part2,
        result = "";

      parts = value.toString().split(".", 2);
      part1 = parts[0].split("");
      part2 = parts[1];

      if (part1.length % 3 == 1) {
        result += part1[0] + ".";
        part1.shift();
      } else if (part1.length % 3 == 2) {
        result += part1[0] + part1[1] + ".";
        part1.shift();
        part1.shift();
      }

      for (var i = 0; i < part1.length - 2; i = i + 3)
        result += part1[i] + part1[i + 1] + part1[i + 2] + ".";

      result = result.slice(0, -1);

      if (part2) result += "," + part2;

      if (negative) return "€ -" + result;
      else return "€ " + result;
    } else return "€ 0";
  }
}
