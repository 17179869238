import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-shop-view",
  templateUrl: "./shop-view.component.html",
  styleUrls: ["./shop-view.component.scss"],
})
export class ShopViewComponent implements OnInit {
  categorys: Array<any> = [];
  products: Array<any> = [];
  rProducts: Array<any> = [];

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getShopPrimaryCategories().subscribe(
      (res) => {
        this.categorys = res["data"];
        console.log(this.categorys);
      },
      (err) => {
        console.log(err);
      }
    );
    this.apiService.getShopAllProducts().subscribe(
      (res) => {
        this.products = res["data"];
        console.log(this.products);
      },
      (err) => {
        console.log(err);
      }
    );
    this.apiService.getShopRecommendedProducts().subscribe(
      (res) => {
        this.rProducts = res["data"];
        console.log(this.rProducts);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  search(event) {
    console.log(event);
  }

  select(event) {
    console.log(event);
  }
}
