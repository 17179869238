import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import * as L from 'leaflet';

import { ApiService } from "../../../../../_services/api.service";

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;

@Component({
  selector: "app-provider-view5",
  templateUrl: "./provider-view5.component.html",
  styleUrls: ["./provider-view5.component.scss"],
})
export class ProviderView5Component implements OnInit {
  @Input() provider;

  private map;
  appointmentForm: FormGroup = null;
  submitted: boolean = false;

  private initMap(lat, lng): void {
    this.map = L.map('map', {
      center: [ lat, lng ],
      zoom: 3
    });
    const marker = L.marker([lat, lng]);
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map);
    marker.addTo(this.map);
  }

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.appointmentForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      message: ["", Validators.required],
    });
  }
  ngAfterViewInit(): void {
    this.initMap(this.provider.lat, this.provider.lng);
  }
  get appointmentF() {
    return this.appointmentForm.controls;
  }

  appointment() {
    this.submitted = true;

    if (this.appointmentForm.valid) {
      var req = {
        user_id: this.provider.user_id,
        first_name: this.appointmentF["firstName"].value,
        last_name: this.appointmentF["lastName"].value,
        email: this.appointmentF["email"].value,
        message: this.appointmentF["message"].value,
      };

      this.apiService.postProviderAppointment(req).subscribe(
        (res) => {
          this.notifierService.notify("success", "Appointment success.");
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    }
  }
}
