import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tos-customer1",
  templateUrl: "./tos-customer1.component.html",
  styleUrls: ["./tos-customer1.component.scss"],
})
export class TosCustomer1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
