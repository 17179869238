import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contact3",
  templateUrl: "./contact3.component.html",
  styleUrls: ["./contact3.component.scss"],
})
export class Contact3Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
