import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-category-super3",
  templateUrl: "./category-super3.component.html",
  styleUrls: ["./category-super3.component.scss"],
})
export class CategorySuper3Component implements OnInit {
  @Input() category;

  categorys: Array<any> = [];

  constructor() {}

  ngOnInit() {
    this.categorys = this.category.posts;
  }
}
