import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  descriptions: any = [];

  constructor(private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    if (this.router.url == "/login")
      this.descriptions.push("Bitte melden Sie sich an.");
    else if (this.router.url.includes("/remind-password"))
      this.descriptions.push("Bitte Tragen Sie Ihre E-Mail ein.");
    else if (this.router.url.includes("/reset-password"))
      this.descriptions.push("Bitte Tragen Sie Ihr neues Passwort ein.");
    else if (this.router.url == "/register-customer")
      this.descriptions.push("Registriere dich, um alle Funktionen zu nutzen.");
    else if (this.router.url == "/register-provider")
      this.descriptions.push(
        "Registrieren Sie sich in vier Schritten",
        "als Anbieter und profitieren Sie von allen",
        "Funktionen des Portals."
      );
    else if (this.router.url == "/register-provider-success")
      this.descriptions.push("Ihre Anfrage wurde versendet");
  }

  ngOnInit() {}
}
