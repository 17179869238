import { Component, OnInit, Input } from "@angular/core";
import * as $ from "jquery";

@Component({
  selector: "app-category-simple2",
  templateUrl: "./category-simple2.component.html",
  styleUrls: ["./category-simple2.component.scss"],
})
export class CategorySimple2Component implements OnInit {
  @Input() category;

  isMobile: boolean = false;

  constructor() {}

  ngOnInit() {
    this.resize();
  }

  resize() {
    if ($("body").width() >= 576) this.isMobile = false;
    else this.isMobile = true;
  }
}
