import { Component, OnInit } from "@angular/core";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {
  categorys: Array<any> = [];

  keyword: any = "";

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.getCategorys();
  }

  search(event) {
    this.keyword = event.keyword;

    this.getCategorys();
  }

  getCategorys() {
    this.apiService.getPortalCategorys(this.keyword).subscribe(
      (res) => {
        this.categorys = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
