import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  private apiUrl = environment.apiUrl;
  url: any;
  req: any;

  constructor(private http: HttpClient) {}

  public process(file, type) {
    this.url = this.apiUrl + "upload";

    this.req = new FormData();

    this.req.append("file", file);
    this.req.append("type", type);

    return this.http.post(this.url, this.req);
  }
}
