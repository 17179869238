import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-category-simple1",
  templateUrl: "./category-simple1.component.html",
  styleUrls: ["./category-simple1.component.scss"],
})
export class CategorySimple1Component implements OnInit {
  @Input() category;

  constructor() {}

  ngOnInit() {}
}
