import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-lead-single-fixed",
  templateUrl: "./lead-single-fixed.component.html",
  styleUrls: ["./lead-single-fixed.component.scss"],
})
export class LeadSingleFixedComponent implements OnInit {
  @Input() question;
  @Input() savedAnswer;
  @Output("answeringE") answeringE: EventEmitter<any> = new EventEmitter();

  activeAnswer: any = null;

  constructor() {}

  ngOnInit() {
    if (this.savedAnswer) this.activeAnswer = this.savedAnswer;
    else this.activeAnswer = null;
  }

  answering(answer) {
    this.activeAnswer = answer;

    this.answeringE.emit({
      question: this.question.id,
      answer: this.activeAnswer,
      type: "single-fixed",
      position: this.question.position,
      next: this.activeAnswer.overwrite_next_question_pos,
    });
  }
}
