import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CartRoutes } from "./cart.routing";
import { VrModule } from "../../vr/vr.module";

import { CartComponent } from "./cart/cart.component";
import { Cart1Component } from "./cart/sections/cart1/cart1.component";
import { Cart2Component } from "./cart/sections/cart2/cart2.component";

import { CartAddressComponent } from "./cart-address/cart-address.component";
import { CartAddress1Component } from "./cart-address/sections/cart-address1/cart-address1.component";
import { CartAddress2Component } from "./cart-address/sections/cart-address2/cart-address2.component";

import { CartShippingComponent } from "./cart-shipping/cart-shipping.component";
import { CartShipping1Component } from "./cart-shipping/sections/cart-shipping1/cart-shipping1.component";
import { CartShipping2Component } from "./cart-shipping/sections/cart-shipping2/cart-shipping2.component";

import { CartPaymentComponent } from "./cart-payment/cart-payment.component";
import { CartPayment1Component } from "./cart-payment/sections/cart-payment1/cart-payment1.component";
import { CartPayment2Component } from "./cart-payment/sections/cart-payment2/cart-payment2.component";

import { CartOrderComponent } from "./cart-order/cart-order.component";
import { CartOrder1Component } from "./cart-order/sections/cart-order1/cart-order1.component";
import { CartOrder2Component } from "./cart-order/sections/cart-order2/cart-order2.component";
import { CartOrder3Component } from "./cart-order/sections/cart-order3/cart-order3.component";
import { CartOrder4Component } from "./cart-order/sections/cart-order4/cart-order4.component";

import { CartProgressComponent } from "./_common/cart-progress/cart-progress.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(CartRoutes),
    NgbModule,
    VrModule,
  ],
  declarations: [
    CartComponent,
    Cart1Component,
    Cart2Component,

    CartAddressComponent,
    CartAddress1Component,
    CartAddress2Component,

    CartShippingComponent,
    CartShipping1Component,
    CartShipping2Component,

    CartPaymentComponent,
    CartPayment1Component,
    CartPayment2Component,

    CartOrderComponent,
    CartOrder1Component,
    CartOrder2Component,
    CartOrder3Component,
    CartOrder4Component,

    CartProgressComponent,
  ],
})
export class CartModule {}
