import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SearchGlobalComponent } from "./search-global/search-global.component";
import { SearchHeaderComponent } from "./search-header/search-header.component";
import { SearchSuperComponent } from "./search-super/search-super.component";
import { SearchSimpleComponent } from "./search-simple/search-simple.component";
import { SearchPostalComponent } from "./search-postal/search-postal.component";
import { ToPortalComponent } from "./to-portal/to-portal.component";
import { ToShopComponent } from "./to-shop/to-shop.component";
import { ShopMenuComponent } from "./shop-menu/shop-menu.component";
import { ShopRecommendComponent } from "./shop-recommend/shop-recommend.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { ComingSoonAccountComponent } from "./coming-soon-account/coming-soon-account.component";

import { TruncatePipe } from "./truncate.pipe";
import { MoneyPipe } from "./money.pipe";
import { SafeurlPipe } from "./safeurl.pipe";
import { SafehtmlPipe } from "./safehtml.pipe";

@NgModule({
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule],
  declarations: [
    SearchGlobalComponent,
    SearchHeaderComponent,
    SearchSuperComponent,
    SearchSimpleComponent,
    SearchPostalComponent,
    ToPortalComponent,
    ToShopComponent,
    ShopMenuComponent,
    ShopRecommendComponent,
    ComingSoonComponent,
    ComingSoonAccountComponent,

    TruncatePipe,
    MoneyPipe,
    SafeurlPipe,
    SafehtmlPipe,
  ],
  exports: [
    SearchGlobalComponent,
    SearchHeaderComponent,
    SearchSuperComponent,
    SearchSimpleComponent,
    SearchPostalComponent,
    ToPortalComponent,
    ToShopComponent,
    ShopMenuComponent,
    ShopRecommendComponent,
    ComingSoonComponent,
    ComingSoonAccountComponent,

    TruncatePipe,
    MoneyPipe,
    SafeurlPipe,
    SafehtmlPipe,
  ],
})
export class VrModule {}
