import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-provider-news",
  templateUrl: "./provider-news.component.html",
  styleUrls: ["./provider-news.component.scss"],
})
export class ProviderNewsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
