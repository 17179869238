import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { AuthService } from "../../../../_services/auth.service";
import { ApiService } from "../../../../_services/api.service";
import { StorageService } from "../../../../_services/storage.service";

@Component({
  selector: "app-provider-join-forming",
  templateUrl: "./provider-join-forming.component.html",
  styleUrls: ["./provider-join-forming.component.scss"],
})
export class ProviderJoinFormingComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private authService: AuthService,
    private apiService: ApiService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    var checkoutId = this.route.snapshot.queryParamMap.get("id");

    this.apiService.getVrp(checkoutId).subscribe(
      (res) => {
        this.storageService.set("subscribed", true);
        this.notifierService.notify("success", "Payment subscription success.");
      },
      (err) => {
        console.log(err);
        this.notifierService.notify("error", err.error.message);
      }
    );
  }

  logout() {
    this.authService.logout();
  }
}
