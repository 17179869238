import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NotifierService } from "angular-notifier";

import { ApiService } from "../../../../_services/api.service";
import { UtilService } from "../../../../_services/util.service";
import { UploadService } from "../../../../_services/upload.service";

@Component({
  selector: "app-provider-profile",
  templateUrl: "./provider-profile.component.html",
  styleUrls: ["./provider-profile.component.scss"],
})
export class ProviderProfileComponent implements OnInit {
  updateForm: FormGroup = null;
  submitted: boolean = false;

  gender: any = "";
  preferences: Array<any> = [];
  companyLogo: any = "";
  thumbnail: any = "";
  image: any = "";

  industrys: Array<any> = [];

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private apiService: ApiService,
    private utilService: UtilService,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    this.updateForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        companyName: ["", Validators.required],
        phone: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/
            ),
          ],
        ],
        email: ["", [Validators.required, Validators.email]],
        secondEmail: [""],
        street: ["", Validators.required],
        streetNumber: ["", Validators.required],
        postalCode: ["", Validators.required],
        city: ["", Validators.required],
        description: ["", Validators.required],
        password: [""],
        passwordConf: [""],
      },
      {
        validators: [this.utilService.mustMatch("password", "passwordConf")],
      }
    );

    this.apiService.getProfileProvider().subscribe(
      (res) => {
        var provider = res["data"];

        this.updateF.firstName.setValue(provider.first_name);
        this.updateF.lastName.setValue(provider.last_name);
        this.updateF.companyName.setValue(provider.company_name);
        this.updateF.phone.setValue(provider.phone);
        this.updateF.email.setValue(provider.email);
        this.updateF.secondEmail.setValue(provider.second_email);
        this.updateF.street.setValue(provider.street);
        this.updateF.streetNumber.setValue(provider.street_number);
        this.updateF.postalCode.setValue(provider.postal_code);
        this.updateF.city.setValue(provider.city);
        this.updateF.description.setValue(provider.description);
        this.updateF.password.setValue("");
        this.updateF.passwordConf.setValue("");
        this.gender = provider.gender;
        this.preferences = provider.preferences;
        this.companyLogo = provider.company_logo;
        this.thumbnail = provider.thumbnail;
        this.image = provider.image;
      },
      (err) => {
        console.log(err);
      }
    );

    this.apiService.getIndustrys().subscribe((res) => {
      this.industrys = res["data"];
    });
  }

  get updateF() {
    return this.updateForm.controls;
  }

  update() {
    this.submitted = true;
    if (this.updateF.secondEmail.value!=null && this.updateF.secondEmail.value.length > 0)
      this.updateF.secondEmail.setValidators([Validators.email]);

    var passwordLen = this.updateF.password.value.length;
    if (passwordLen > 0 && passwordLen < 6)
      this.updateF.password.setErrors({ minlength: true });

    var companyLogo='';
    var thumbnail='';
    var image='';

    if(this.companyLogo!=null) {
        companyLogo = '/' + this.companyLogo.split('/').slice(3).join('/');
    }
      if(this.thumbnail!=null) {
          thumbnail = '/' + this.thumbnail.split('/').slice(3).join('/');
      }
      if(this.image!=null) {
          image = '/' + this.image.split('/').slice(3).join('/');
      }
    if (this.updateForm.valid) {
      var req = {
        first_name: this.updateF.firstName.value,
        last_name: this.updateF.lastName.value,
        company_name: this.updateF.companyName.value,
        phone: this.updateF.phone.value,
        email: this.updateF.email.value,
        second_email: this.updateF.secondEmail.value,
        password: this.updateF.password.value,
        street: this.updateF.street.value,
        street_number: this.updateF.streetNumber.value,
        postal_code: this.updateF.postalCode.value,
        city: this.updateF.city.value,
        description: this.updateF.description.value,
        gender: this.gender,
        preferences: this.preferences.map((prefernece) => {
          return prefernece.id;
        }),
        company_logo: companyLogo,
        thumbnail: thumbnail,
        image: image,
      };

      this.apiService.postProfileProvider(req).subscribe(
        (res) => {
          this.notifierService.notify(
            "success",
            "Profil erfolgreich aktualisiert."
          );
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    }
  }

  changeGender(gender) {
    this.gender = gender;
  }

  changeFile(event, field) {
    var reader = new FileReader(),
      file;

    if (event.target.files && event.target.files.length) {
      reader.onload = () => {
        file = event.target.files[0];

        this.uploadService.process(file, "image").subscribe(
          (res) => {
            if (field == "company_logo") this.companyLogo = res["data"];
            else if (field == "thumbnail") this.thumbnail = res["data"];
            else if (field == "image") this.image = res["data"];
          },
          (err) => {
            console.log(err);
          }
        );
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
