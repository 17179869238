import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-provider1",
  templateUrl: "./provider1.component.html",
  styleUrls: ["./provider1.component.scss"],
})
export class Provider1Component implements OnInit {
  @Output("zoneE") zoneE: EventEmitter<any> = new EventEmitter();

  text: any = "Anbieterverzeichnis";

  constructor() {}

  ngOnInit() {}

  zone(event) {
    this.zoneE.emit(event);
  }
}
