import { Component, OnInit } from "@angular/core";
import { NotifierService } from "angular-notifier";
import { StorageService } from "../../../../../_services/storage.service";
import { ApiService } from "../../../../../_services/api.service";

@Component({
  selector: "app-cart2",
  templateUrl: "./cart2.component.html",
  styleUrls: ["./cart2.component.scss"],
})
export class Cart2Component implements OnInit {
  products: Array<any> = [
    {
      image: "assets/img/cart-product-1.png",
      name: "GARTENLICHT",
      quantity: 1,
      price: 24.99,
    },
    {
      image: "assets/img/cart-product-2.png",
      name: "RASENMÄHER",
      quantity: 1,
      price: 249.99,
    },
    {
      image: "assets/img/cart-product-3.png",
      name: "KÜHLSCHRANK",
      quantity: 1,
      price: -249.99,
    },
    {
      image: "assets/img/cart-product-1.png",
      name: "GARTENLICHT",
      quantity: 1,
      price: 24.99,
    },
    {
      image: "assets/img/cart-product-2.png",
      name: "RASENMÄHER",
      quantity: 1,
      price: 249.99,
    },
    {
      image: "assets/img/cart-product-3.png",
      name: "KÜHLSCHRANK",
      quantity: 1,
      price: 249.99,
    },
  ];
  cartInfo: any = {};
  constructor(
    private storageService: StorageService,
    private notifierService: NotifierService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    const cartId = this.storageService.get("cardId");
    if (cartId) {
      this.apiService.getProductsFromCart(cartId).subscribe(
        (res) => {
          this.cartInfo = res['data'];
          // this.storageService.set("cardId", res["data"].cart_key);
          this.notifierService.notify("success", "Inquiry success.");
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    }
  }
  addToCart(productId, quantity) {
    const cartId = this.storageService.get("cardId");
    this.apiService
      .addProductToCart(productId, quantity, cartId)
      .subscribe(
        (res) => {
          this.storageService.set("cardId", res["data"].cart_key);
          this.notifierService.notify("success", "Inquiry success.");
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
  }
  changeQuantity(index, variant) {
    if (this.cartInfo.products[index].quantity + variant >= 1) {
      this.cartInfo.products[index].quantity = this.cartInfo.products[index].quantity + variant;
      this.addToCart(this.cartInfo.products[index].product_id, this.cartInfo.products[index].quantity)
    }
  }
  removeProduct(index, variant) {
    this.addToCart(this.cartInfo.products[index].product_id, 0);
    this.cartInfo.products.splice(index, 1);
  }
}
