import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";

import { StorageService } from "../_services/storage.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var roles = route.data.roles,
      role = this.storageService.get("role");

    if (roles.includes(role)) return true;

    this.router.navigateByUrl("/login");

    return false;
  }
}
