import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-category-view",
  templateUrl: "./category-view.component.html",
  styleUrls: ["./category-view.component.scss"],
})
export class CategoryViewComponent implements OnInit {
  category: any = null;

  keyword: any = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.getCategory();
  }

  search(event) {
    this.keyword = event.keyword;

    this.getCategory();
  }

  getCategory() {
    var url = this.route.snapshot.params["url"];

    this.apiService.getPortalCategory(url, this.keyword).subscribe(
      (res) => {
        if (res["data"]) this.category = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );

    this.apiService.getPortalPost(url, this.keyword).subscribe(
      (res) => {
        if (res["data"] && !res["data"].content) this.category = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
