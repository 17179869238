import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-search-simple",
  templateUrl: "./search-simple.component.html",
  styleUrls: ["./search-simple.component.scss"],
})
export class SearchSimpleComponent implements OnInit {
  @Output("searchE") searchE: EventEmitter<any> = new EventEmitter();

  keyword: any = "";

  constructor() {}

  ngOnInit() {}

  keypress(event) {
    if ((event.keyCode ? event.keyCode : event.which) == 13) this.search();
  }

  search() {
    this.searchE.emit({
      keyword: this.keyword,
    });
  }
}
