import { Component, OnInit, Input } from "@angular/core";
import * as $ from "jquery";

@Component({
  selector: "app-shop-recommend",
  templateUrl: "./shop-recommend.component.html",
  styleUrls: ["./shop-recommend.component.scss"],
})
export class ShopRecommendComponent implements OnInit {
  @Input() rProducts;

  cycles: Array<any> = [];

  constructor() {}

  ngOnInit() {
    this.resize();
  }

  resize(width = 0) {
    this.cycles = [];

    var cntPerView;

    if (width == 0) width = $("body").width();

    if (width >= 1600) cntPerView = 7;
    else if (width >= 1200 && width <= 1599) cntPerView = 6;
    else if (width >= 992 && width <= 1199) cntPerView = 5;
    else if (width >= 768 && width <= 991) cntPerView = 4;
    else if (width >= 576 && width <= 767) cntPerView = 3;
    else if (width >= 320 && width <= 575) cntPerView = 2;

    var cycle;

    for (var i = 0; i < this.rProducts.length; i += cntPerView) {
      cycle = [];

      for (var j = i; j < i + cntPerView; j++) {
        if (this.rProducts[j]) cycle.push(this.rProducts[j]);
        else break;
      }

      this.cycles.push(cycle);
    }
  }
}
