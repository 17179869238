import { Component, OnInit, Input } from "@angular/core";
import * as $ from "jquery";

@Component({
  selector: "app-provider-view3",
  templateUrl: "./provider-view3.component.html",
  styleUrls: ["./provider-view3.component.scss"],
})
export class ProviderView3Component implements OnInit {
  @Input() provider;

  isMobile: boolean = false;

  constructor() {}

  ngOnInit() {
    this.resize();
  }

  resize() {
    if ($("body").width() >= 576) this.isMobile = false;
    else this.isMobile = true;
  }
}
