import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-shop-menu",
  templateUrl: "./shop-menu.component.html",
  styleUrls: ["./shop-menu.component.scss"],
})
export class ShopMenuComponent implements OnInit {
  @Input() categorys;
  @Output("selectE") selectE: EventEmitter<any> = new EventEmitter();

  groups: Array<any> = [];

  constructor() {}

  ngOnInit() {
    var group = [],
      counter = 0;

    for (var i = 0; i < this.categorys.length; i++) {
      group.push(this.categorys[i]);

      counter++;

      if (counter == 8 || i == this.categorys.length - 1) {
        this.groups.push(group);

        group = [];

        counter = 0;
      }
    }
  }

  select(id) {
    this.selectE.emit({
      id: id,
    });
  }
}
