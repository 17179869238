import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxGalleryModule } from "@kolkov/ngx-gallery";

import { WorldRoutes } from "./world.routing";
import { VrModule } from "../../vr/vr.module";

import { CategoryComponent } from "./category/category.component";
import { Category1Component } from "./category/sections/category1/category1.component";
import { Category2Component } from "./category/sections/category2/category2.component";
import { Category3Component } from "./category/sections/category3/category3.component";
import { Category4Component } from "./category/sections/category4/category4.component";
import { Category5Component } from "./category/sections/category5/category5.component";
import { Category6Component } from "./category/sections/category6/category6.component";
import { Category7Component } from "./category/sections/category7/category7.component";
import { Category8Component } from "./category/sections/category8/category8.component";
import { Category9Component } from "./category/sections/category9/category9.component";
import { Category10Component } from "./category/sections/category10/category10.component";
import { Category11Component } from "./category/sections/category11/category11.component";

import { CategoryCategoryComponent } from "./category-category/category-category.component";
import { CategoryCategory1Component } from "./category-category/sections/category-category1/category-category1.component";
import { CategoryCategory2Component } from "./category-category/sections/category-category2/category-category2.component";
import { CategoryCategory3Component } from "./category-category/sections/category-category3/category-category3.component";
import { CategoryCategory4Component } from "./category-category/sections/category-category4/category-category4.component";

import { CategoryContentComponent } from "./category-content/category-content.component";
import { CategoryContent1Component } from "./category-content/sections/category-content1/category-content1.component";
import { CategoryContent2Component } from "./category-content/sections/category-content2/category-content2.component";
import { CategoryContent3Component } from "./category-content/sections/category-content3/category-content3.component";

import { CategoryListingComponent } from "./category-listing/category-listing.component";
import { CategoryListing1Component } from "./category-listing/sections/category-listing1/category-listing1.component";
import { CategoryListing2Component } from "./category-listing/sections/category-listing2/category-listing2.component";
import { CategoryListing3Component } from "./category-listing/sections/category-listing3/category-listing3.component";
import { CategoryListing4Component } from "./category-listing/sections/category-listing4/category-listing4.component";

import { CategoryContactComponent } from "./category-contact/category-contact.component";
import { CategoryContact1Component } from "./category-contact/sections/category-contact1/category-contact1.component";
import { CategoryContact2Component } from "./category-contact/sections/category-contact2/category-contact2.component";
import { CategoryContact3Component } from "./category-contact/sections/category-contact3/category-contact3.component";
import { CategoryContact4Component } from "./category-contact/sections/category-contact4/category-contact4.component";
import { CategoryContact5Component } from "./category-contact/sections/category-contact5/category-contact5.component";

import { CategoryContactingComponent } from "./category-contacting/category-contacting.component";
import { CategoryContacting1Component } from "./category-contacting/sections/category-contacting1/category-contacting1.component";
import { CategoryContacting2Component } from "./category-contacting/sections/category-contacting2/category-contacting2.component";
import { CategoryContacting3Component } from "./category-contacting/sections/category-contacting3/category-contacting3.component";

import { CategoryLeadComponent } from "./category-lead/category-lead.component";
import { CategoryLead1Component } from "./category-lead/sections/category-lead1/category-lead1.component";
import { CategoryLead2Component } from "./category-lead/sections/category-lead2/category-lead2.component";

import { CategoryLeadingConfirmComponent } from "./category-leading-confirm/category-leading-confirm.component";
import { CategoryLeadingConfirm1Component } from "./category-leading-confirm/sections/category-leading-confirm1/category-leading-confirm1.component";
import { CategoryLeadingConfirm2Component } from "./category-leading-confirm/sections/category-leading-confirm2/category-leading-confirm2.component";

import { CategoryLeadingSubmitComponent } from "./category-leading-submit/category-leading-submit.component";
import { CategoryLeadingSubmit1Component } from "./category-leading-submit/sections/category-leading-submit1/category-leading-submit1.component";
import { CategoryLeadingSubmit2Component } from "./category-leading-submit/sections/category-leading-submit2/category-leading-submit2.component";

import { LeadSingleFixedComponent } from "./category-lead/types/lead-single-fixed/lead-single-fixed.component";
import { LeadSingleRangeComponent } from "./category-lead/types/lead-single-range/lead-single-range.component";
import { LeadSingleTextComponent } from "./category-lead/types/lead-single-text/lead-single-text.component";
import { LeadSingleDateComponent } from "./category-lead/types/lead-single-date/lead-single-date.component";
import { LeadSingleDaterangeComponent } from "./category-lead/types/lead-single-daterange/lead-single-daterange.component";
import { LeadMultipleFixedComponent } from "./category-lead/types/lead-multiple-fixed/lead-multiple-fixed.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(WorldRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGalleryModule,
    VrModule,
  ],
  declarations: [
    CategoryComponent,
    Category1Component,
    Category2Component,
    Category3Component,
    Category4Component,
    Category5Component,
    Category6Component,
    Category7Component,
    Category8Component,
    Category9Component,
    Category10Component,
    Category11Component,

    CategoryCategoryComponent,
    CategoryCategory1Component,
    CategoryCategory2Component,
    CategoryCategory3Component,
    CategoryCategory4Component,

    CategoryContentComponent,
    CategoryContent1Component,
    CategoryContent2Component,
    CategoryContent3Component,

    CategoryListingComponent,
    CategoryListing1Component,
    CategoryListing2Component,
    CategoryListing3Component,
    CategoryListing4Component,

    CategoryContactComponent,
    CategoryContact1Component,
    CategoryContact2Component,
    CategoryContact3Component,
    CategoryContact4Component,
    CategoryContact5Component,

    CategoryContactingComponent,
    CategoryContacting1Component,
    CategoryContacting2Component,
    CategoryContacting3Component,

    CategoryLeadComponent,
    CategoryLead1Component,
    CategoryLead2Component,

    CategoryLeadingConfirmComponent,
    CategoryLeadingConfirm1Component,
    CategoryLeadingConfirm2Component,

    CategoryLeadingSubmitComponent,
    CategoryLeadingSubmit1Component,
    CategoryLeadingSubmit2Component,

    LeadSingleFixedComponent,
    LeadSingleRangeComponent,
    LeadSingleTextComponent,
    LeadSingleDateComponent,
    LeadSingleDaterangeComponent,
    LeadMultipleFixedComponent,
  ],
})
export class WorldModule {}
