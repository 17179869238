import { Routes } from "@angular/router";

import { ShopComponent } from "./shop/shop.component";
import { ShopViewComponent } from "./shop-view/shop-view.component";
import { ProductComponent } from "./product/product.component";

export const ShopRoutes: Routes = [
  {
    path: "shop",
    children: [
      {
        path: "",
        component: ShopComponent,
      },
      {
        path: "product/:id",
        component: ProductComponent,
      },
      {
        path: "view",
        component: ShopViewComponent,
      },
    ],
  },
];
