import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-provider",
  templateUrl: "./provider.component.html",
  styleUrls: ["./provider.component.scss"],
})
export class ProviderComponent implements OnInit {
  @ViewChild("cookieModal")
  private cookieModalTpl: TemplateRef<any>;

  industrysAll: Array<any> = [];
  industries: Array<any> = [];
  prefixs: Array<any> = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "ALLE",
  ];

  providers: Array<any> = [];
  collectionSize: any = 0;

  postalCode: any = "";
  radius: any = "";
  keyword: any = "";
  industrys: Array<any> = [];
  prefix: any = "";
  sort: any = "";
  pageSize: any = "";
  page: any = "";

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) {}

  buildIndustryStructure(allIndustries) {
    let industries = {};
    allIndustries.forEach((industry) => {
      const { name, id, parent_category, parent_id, thumbnail, url } = industry;
      const category = parent_category === "" ? "other" : parent_category;
      if (parent_category === "") {
        if (!industries[name]) {
          industries[name] = { ...industry, children: [] };
        }
      } else {
        if (industries[category]) {
          industries[category].children.push(industry);
        } else {
          industries[category] = {
            name: category,
            id: parent_id,
            children: [industry],
          };
        }
      }
    });
    this.industries = Object.keys(industries).map(
      (parent_category) => industries[parent_category]
    );
  }
  ngOnInit() {
    this.apiService.getIndustrys().subscribe(
      (res) => {
        this.industrysAll = res["data"];
        this.buildIndustryStructure(res["data"]);
        this.getProviders();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngAfterViewInit() {
    if (!this.storage.get("cookieModal")) {
      this.modalService.open(this.cookieModalTpl, { size: "xl" });
      this.storage.set("cookieModal", true);
    }
  }

  zone(event) {
    this.postalCode = event.postalCode;
    this.radius = event.radius;
  }

  search(event) {
    this.keyword = event.keyword;

    this.getProviders();
  }

  control(event) {
    this.industrys = event.industrys;
    this.prefix = event.prefix;
    this.sort = event.sort;
    this.pageSize = event.pageSize;
    this.page = event.page;

    this.getProviders();
  }
  replaceCategories() {
    this.providers = this.providers.map((provider) => {
      const { categories } = provider;
      const categoriesList =
        categories &&
        this.industrysAll.filter((industry) =>
          JSON.parse(categories).includes(industry.id.toString())
        );
      return {
        ...provider,
        categories: categoriesList,
      };
    });
  }
  getProviders() {
    this.apiService
      .getProviders(
        this.postalCode,
        this.radius,
        this.keyword,
        this.industrys,
        this.prefix,
        this.sort,
        this.pageSize,
        this.page
      )
      .subscribe(
        (res) => {
          this.providers = res["data"];
          this.replaceCategories();
          this.collectionSize = res["meta"]["total"];
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
