import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AuthLayoutComponent } from "./layout/auth/auth-layout.component";
import { MainLayoutComponent } from "./layout/main/main-layout.component";

const routes: Routes = [
  { path: "", redirectTo: "world-category", pathMatch: "full" },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "./pages/auth/auth.module#AuthModule",
      },
    ],
  },
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      { path: "", loadChildren: "./pages/world/world.module#WorldModule" },
      {
        path: "",
        loadChildren: "./pages/provider/provider.module#ProviderModule",
      },
      { path: "", loadChildren: "./pages/portal/portal.module#PortalModule" },
      { path: "", loadChildren: "./pages/shop/shop.module#ShopModule" },
      {
        path: "",
        loadChildren: "./pages/account/account.module#AccountModule",
      },
      { path: "", loadChildren: "./pages/cart/cart.module#CartModule" },
      { path: "", loadChildren: "./pages/help/help.module#HelpModule" },
    ],
  },
  { path: "**", redirectTo: "world-category" },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
