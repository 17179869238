import { Routes } from "@angular/router";

import { ProviderComponent } from "./provider/provider.component";
import { ProviderViewComponent } from "./provider-view/provider-view.component";

export const ProviderRoutes: Routes = [
  {
    path: "provider",
    children: [
      {
        path: "",
        component: ProviderComponent,
      },
      {
        path: "view/:url",
        component: ProviderViewComponent,
      },
    ],
  },
];
