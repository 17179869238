import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ApiService } from "../../../_services/api.service";
import { StorageService } from "../../../_services/storage.service";

@Component({
  selector: "app-category-leading-submit",
  templateUrl: "./category-leading-submit.component.html",
  styleUrls: ["./category-leading-submit.component.scss"],
})
export class CategoryLeadingSubmitComponent implements OnInit {
  result: any = "";

  constructor(
    private router: Router,
    private apiService: ApiService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    var lead = this.storageService.get("lead");

    if (lead) {
      this.apiService.postLead(lead).subscribe(
        (res) => {
          this.storageService.remove("lead");
          this.result = "success";
        },
        (err) => {
          console.log(err);
          this.result = "failure";
        }
      );
    } else this.router.navigateByUrl("/account/customer-lead");
  }
}
