import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-order4",
  templateUrl: "./cart-order4.component.html",
  styleUrls: ["./cart-order4.component.scss"],
})
export class CartOrder4Component implements OnInit {
  articles: Array<any> = [
    {
      code1: "TKVD15-G3-HJ",
      code2: "BOSCH-Rexroth",
      amount: 1,
      price: 139,
    },
    {
      code1: "TKVD15-G3-HJ",
      code2: "BOSCH-Rexroth",
      amount: 1,
      price: 139,
    },
  ];

  constructor() {}

  ngOnInit() {}
}
