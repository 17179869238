import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-faq-customer2",
  templateUrl: "./faq-customer2.component.html",
  styleUrls: ["./faq-customer2.component.scss"],
})
export class FaqCustomer2Component implements OnInit {
  @Input() faqCustomer;

  constructor() {}

  ngOnInit() {}
}
