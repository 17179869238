import { Routes } from "@angular/router";

import { CategoryComponent } from "./category/category.component";
import { CategoryCategoryComponent } from "./category-category/category-category.component";
import { CategoryContentComponent } from "./category-content/category-content.component";
import { CategoryListingComponent } from "./category-listing/category-listing.component";
import { CategoryContactComponent } from "./category-contact/category-contact.component";
import { CategoryContactingComponent } from "./category-contacting/category-contacting.component";
import { CategoryLeadComponent } from "./category-lead/category-lead.component";
import { CategoryLeadingConfirmComponent } from "./category-leading-confirm/category-leading-confirm.component";
import { CategoryLeadingSubmitComponent } from "./category-leading-submit/category-leading-submit.component";

import { AuthGuard } from "../../_guards/auth.guard";
import { RoleGuard } from "../../_guards/role.guard";

export const WorldRoutes: Routes = [
  {
    path: "world-category",
    children: [
      {
        path: "",
        component: CategoryComponent,
      },
      {
        path: "category/:url",
        component: CategoryCategoryComponent,
      },
      {
        path: "content/:url",
        component: CategoryContentComponent,
      },
      {
        path: "listing/:url",
        component: CategoryListingComponent,
      },
      {
        path: "contact/:url",
        component: CategoryContactComponent,
      },
      {
        path: "contacting/:url",
        component: CategoryContactingComponent,
      },
      {
        path: "lead/:url",
        component: CategoryLeadComponent,
      },
      {
        path: "leading-confirm",
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ["customer"] },
        component: CategoryLeadingConfirmComponent,
      },
      {
        path: "leading-submit",
        canActivate: [AuthGuard, RoleGuard],
        data: { roles: ["customer"] },
        component: CategoryLeadingSubmitComponent,
      },
    ],
  },
];
