import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contact1",
  templateUrl: "./contact1.component.html",
  styleUrls: ["./contact1.component.scss"],
})
export class Contact1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
