import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-customer-financing",
  templateUrl: "./customer-financing.component.html",
  styleUrls: ["./customer-financing.component.scss"],
})
export class CustomerFinancingComponent implements OnInit {
  iframe_url: any =
    "https://angebot.easycredit.de/ece/pb/informieren?pk=1.de.717.9999";
  constructor() {}

  ngOnInit() {}
}
