import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-category10",
  templateUrl: "./category10.component.html",
  styleUrls: ["./category10.component.scss"],
})
export class Category10Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
