import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotifierModule } from "angular-notifier";

import { AuthRoutes } from "./auth.routing";

import { LoginComponent } from "./login/login.component";
import { RemindPasswordComponent } from "./remind-password/remind-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RegisterCustomerComponent } from "./register-customer/register-customer.component";
import { RegisterProviderComponent } from "./register-provider/register-provider.component";
import { RegisterProviderSuccessComponent } from "./register-provider-success/register-provider-success.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule,
  ],
  declarations: [
    LoginComponent,
    RemindPasswordComponent,
    ResetPasswordComponent,
    RegisterCustomerComponent,
    RegisterProviderComponent,
    RegisterProviderSuccessComponent,
    VerifyEmailComponent,
  ],
})
export class AuthModule {}
