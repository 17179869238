import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-payment2",
  templateUrl: "./cart-payment2.component.html",
  styleUrls: ["./cart-payment2.component.scss"],
})
export class CartPayment2Component implements OnInit {
  payments: Array<any> = [
    {
      name: "visa-master",
      image: "assets/img/cart-payment-visa-master.png",
      information: "visa-master",
    },
    {
      name: "paypal",
      image: "assets/img/cart-payment-paypal.png",
      information: "paypal",
    },
    {
      name: "sepa",
      image: "assets/img/cart-payment-sepa.png",
      information: "sepa",
    },
    {
      name: "invoice-alternate",
      image: "assets/img/cart-payment-invoice-alternate.png",
      information: "invoice-alternate",
    },
  ];

  products: Array<any> = [
    {
      image: "assets/img/cart-product-1.png",
      name: "GARTENLICHT",
      quantity: 1,
      price: 24.99,
    },
    {
      image: "assets/img/cart-product-2.png",
      name: "RASENMÄHER",
      quantity: 1,
      price: 249.99,
    },
    {
      image: "assets/img/cart-product-3.png",
      name: "KÜHLSCHRANK",
      quantity: 1,
      price: -249.99,
    },
    {
      image: "assets/img/cart-product-1.png",
      name: "GARTENLICHT",
      quantity: 1,
      price: 24.99,
    },
    {
      image: "assets/img/cart-product-2.png",
      name: "RASENMÄHER",
      quantity: 1,
      price: 249.99,
    },
    {
      image: "assets/img/cart-product-3.png",
      name: "KÜHLSCHRANK",
      quantity: 1,
      price: 249.99,
    },
  ];

  constructor() {}

  ngOnInit() {}
}
