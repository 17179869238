import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-search-postal",
  templateUrl: "./search-postal.component.html",
  styleUrls: ["./search-postal.component.scss"],
})
export class SearchPostalComponent implements OnInit {
  @Input() text;
  @Output("zoneE") zoneE: EventEmitter<any> = new EventEmitter();

  postalCode: any = "";
  radius: any = "";

  constructor() {}

  ngOnInit() {}

  changePostalCode(postalCode) {
    this.postalCode = postalCode;

    this.zone();
  }

  selectRadius(radius) {
    this.radius = radius;

    this.zone();
  }

  zone() {
    this.zoneE.emit({
      postalCode: this.postalCode,
      radius: this.radius,
    });
  }
}
