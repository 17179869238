import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as $ from "jquery";

import { StorageService } from "../../../../../_services/storage.service";

@Component({
  selector: "app-category-lead2",
  templateUrl: "./category-lead2.component.html",
  styleUrls: ["./category-lead2.component.scss"],
})
export class CategoryLead2Component implements OnInit {
  @Input() category;
  @Output("postLeadE") postLeadE: EventEmitter<any> = new EventEmitter();

  questions: Array<any> = [];
  index: any = 0;
  pointer: any = 0;
  flag: boolean = true;

  /** post lead counter **/
  counter: any = 0;

  /** check if mobile size **/
  isMobile: boolean = false;

  constructor(private storageService: StorageService) {}

  ngOnInit() {
    this.questions = this.category.params.questions;

    for (var i = 0; i < this.questions.length - 1; i++)
      for (var j = 0; j < this.questions[i].answers.length; j++)
        if (!this.questions[i].answers[j].overwrite_next_question_pos)
          this.questions[i].answers[
            j
          ].overwrite_next_question_pos = this.questions[i + 1].position;

    this.resize();
  }

  getIndexByPos(position) {
    return this.questions.findIndex(
      (question) => question.position == position
    );
  }

  getSavedAnswer(only) {
    var answers = this.storageService.get("answers");

    if (answers[this.pointer]) {
      if (only) return answers[this.pointer].answer;
      else return answers[this.pointer];
    } else return null;
  }

  answering(answer) {
    var answers = this.storageService.get("answers");

    if (answers[this.pointer]) answers[this.pointer] = answer;
    else answers.push(answer);

    this.storageService.set("answers", answers);
  }

  getNxtState() {
    var savedAnswer = this.getSavedAnswer(false);

    if (this.questions[this.index].type == "single")
      if (savedAnswer) if (savedAnswer.answer) return true;

    if (this.questions[this.index].type == "multiple")
      if (savedAnswer)
        if (savedAnswer.answer && savedAnswer.answer.length) return true;

    return false;
  }

  prev() {
    this.flag = false;

    setTimeout(() => {
      var answers = this.storageService.get("answers");

      if (this.pointer > 0) {
        answers.splice(this.pointer);
        this.storageService.set("answers", answers);

        this.pointer--;
        this.index = this.getIndexByPos(answers[this.pointer].position);
      }

      /** post lead counter **/
      this.counter = 0;

      this.flag = true;
    }, 10);
  }

  nxt() {
    this.flag = false;

    setTimeout(() => {
      var savedAnswer = this.getSavedAnswer(false);

      if (this.questions[this.index].type == "single") {
        if (savedAnswer) {
          if (savedAnswer.answer) {
            if (savedAnswer.next) {
              this.index = this.getIndexByPos(savedAnswer.next);
              this.pointer++;
            }
          }
        }
      }

      if (this.questions[this.index].type == "multiple") {
        if (savedAnswer) {
          if (savedAnswer.answer && savedAnswer.answer.length) {
            if (savedAnswer.next) {
              this.index = this.getIndexByPos(savedAnswer.next);
              this.pointer++;
            }
          }
        }
      }

      /** post lead counter **/
      if (this.index == this.questions.length - 1) this.counter++;
      if (this.counter == 2) this.postLead();

      this.flag = true;
    }, 10);
  }

  postLead() {
    var answers = this.storageService.get("answers");

    for (var i = 0; i < answers.length; i++) {
      if (answers[i].type == "single-fixed")
        answers[i].answer = answers[i].answer.id;
      if (answers[i].type == "multiple-fixed")
        answers[i].answer = answers[i].answer.map((answer) => {
          return answer.id;
        });

      delete answers[i].position;
      delete answers[i].next;
    }

    this.postLeadE.emit({
      lead_route_id: this.category.params.id,
      answers: answers,
    });
  }

  resize() {
    if ($("body").width() >= 576) this.isMobile = false;
    else this.isMobile = true;
  }
}
