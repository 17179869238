import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-category2",
  templateUrl: "./category2.component.html",
  styleUrls: ["./category2.component.scss"],
})
export class Category2Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
