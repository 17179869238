import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-shop-view3",
  templateUrl: "./shop-view3.component.html",
  styleUrls: ["./shop-view3.component.scss"],
})
export class ShopView3Component implements OnInit {
  @Input() categorys;
  @Input() products;

  sort: any = "";

  constructor(private router: Router) {}

  ngOnInit() {}

  changeSort(sort) {
    this.sort = sort;
  }
  productView(product) {
    this.router.navigateByUrl(`/shop/product/${product.id}`);
  }
}
