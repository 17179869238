import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category-leading-confirm2",
  templateUrl: "./category-leading-confirm2.component.html",
  styleUrls: ["./category-leading-confirm2.component.scss"],
})
export class CategoryLeadingConfirm2Component implements OnInit {
  confirmed: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {}

  changeConfirmed(confirmed) {
    this.confirmed = confirmed;
  }

  worldCategoryLeadingSubmit() {
    if (this.confirmed)
      this.router.navigateByUrl("/world-category/leading-submit");
  }
}
