import { Component, OnInit, Input } from "@angular/core";
import { NgxGalleryAnimation } from "@kolkov/ngx-gallery";

@Component({
  selector: "app-category-listing3",
  templateUrl: "./category-listing3.component.html",
  styleUrls: ["./category-listing3.component.scss"],
})
export class CategoryListing3Component implements OnInit {
  @Input() product;

  options: Array<any> = [];
  gallerys: Array<any> = [];

  variant: any = null;

  constructor() {}

  ngOnInit() {
    this.options = [
      {
        thumbnailsArrowsAutoHide: true,
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
    ];

    this.gallerys = this.product.gallerys.map((gallery) => {
      return {
        small: gallery,
        medium: gallery,
        big: gallery,
      };
    });

    this.variant = this.product.variants[0];
  }

  changeVariant(variant) {
    this.variant = variant;
  }
}
