import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { StorageService } from "../../../_services/storage.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  role: any = "";
  name: any = "";
  subscribed: boolean = false;

  constructor(private router: Router, private storageService: StorageService) {}

  ngOnInit() {
    this.role = this.storageService.get("role");
    this.name = this.storageService.get("name");
    this.subscribed = this.storageService.get("subscribed");

    if (this.router.url == "/account")
      this.router.navigateByUrl(`/account/${this.role}-lead`);
  }
}
