import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-imprint2",
  templateUrl: "./imprint2.component.html",
  styleUrls: ["./imprint2.component.scss"],
})
export class Imprint2Component implements OnInit {
  @Input() imprint;

  constructor() {}

  ngOnInit() {}
}
