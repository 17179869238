import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-to-portal",
  templateUrl: "./to-portal.component.html",
  styleUrls: ["./to-portal.component.scss"],
})
export class ToPortalComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  portalCategory() {
    this.router.navigateByUrl("/portal-category");
  }
}
