import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-order2",
  templateUrl: "./cart-order2.component.html",
  styleUrls: ["./cart-order2.component.scss"],
})
export class CartOrder2Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
