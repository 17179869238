import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";
import { StorageService } from "../../../_services/storage.service";

@Component({
  selector: "app-provider-view",
  templateUrl: "./provider-view.component.html",
  styleUrls: ["./provider-view.component.scss"],
})
export class ProviderViewComponent implements OnInit {
  provider: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private storageService: StorageService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    var url = this.route.snapshot.params["url"];

    this.storageService.set("provider-image", "");

    this.apiService.getProvider(url).subscribe(
      (res) => {
        this.provider = res["data"];

        this.storageService.set("provider-image", this.provider.image);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
