import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-category-category1",
  templateUrl: "./category-category1.component.html",
  styleUrls: ["./category-category1.component.scss"],
})
export class CategoryCategory1Component implements OnInit {
  @Input() category;
  @Output("zoneE") zoneE: EventEmitter<any> = new EventEmitter();

  text: any = "";

  constructor() {}

  ngOnInit() {
    this.text = this.category.title;
  }

  zone(event) {
    this.zoneE.emit(event);
  }
}
