import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  product: any = {};
  categorys: Array<any> = [];
  rProducts: Array<any> = [];

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit() {
    var id = this.route.snapshot.params["id"];
    console.log(id);
    this.apiService.getProductDetail(id).subscribe(
      (res) => {
        this.product = res["data"];
        console.log(this.product);
      },
      (err) => {
        console.log(err);
      }
    );
    this.apiService.getShopPrimaryCategories().subscribe(
      (res) => {
        this.categorys = res["data"];
        console.log(this.categorys);
      },
      (err) => {
        console.log(err);
      }
    );
    this.apiService.getShopRecommendedProducts().subscribe(
      (res) => {
        this.rProducts = res["data"];
        console.log(this.rProducts);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  search(event) {
    console.log(event);
  }

  select(event) {
    console.log(event);
  }
}
