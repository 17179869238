import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NotifierService } from "angular-notifier";

import { AuthService } from "../../../_services/auth.service";
import { UtilService } from "../../../_services/util.service";

@Component({
  selector: "app-register-customer",
  templateUrl: "./register-customer.component.html",
  styleUrls: ["./register-customer.component.scss"],
})
export class RegisterCustomerComponent implements OnInit {
  registerForm: FormGroup = null;
  submitted: boolean = false;

  agreed: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private authService: AuthService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        emailConf: ["", Validators.required],
        password: ["", [Validators.required, Validators.minLength(6)]],
        passwordConf: ["", Validators.required],
      },
      {
        validators: [
          this.utilService.mustMatch("email", "emailConf"),
          this.utilService.mustMatch("password", "passwordConf"),
        ],
      }
    );
  }

  get registerF() {
    return this.registerForm.controls;
  }

  changeAgreed(agreed) {
    this.agreed = agreed;
  }

  register() {
    this.submitted = true;

    if (this.registerForm.valid) {
      var req = {
        first_name: this.registerF["firstName"].value,
        last_name: this.registerF["lastName"].value,
        email: this.registerF["email"].value,
        password: this.registerF["password"].value,
      };

      if (this.agreed) {
        this.authService.registerCustomer(req).subscribe(
          (res) => {
            this.notifierService.notify(
              "success",
              "Verification email sent, please check your mail box."
            );
          },
          (err) => {
            console.log(err);
            this.notifierService.notify("error", err.error.message);
          }
        );
      }
    }
  }
}
