import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiUrl = environment.apiUrl;
  url: any;
  req: any;

  constructor(private http: HttpClient) {}

  /** search **/
  public getSearch(keyword) {
    this.url = this.apiUrl + `search?keyword=${keyword}`;
    return this.http.get(this.url);
  }

  /** world **/
  public getWorlds() {
    this.url = this.apiUrl + "worlds";
    return this.http.get(this.url);
  }

  public getWorldCategory(
    url,
    postalCode = "",
    radius = "",
    keyword = "",
    sort = ""
  ) {
    this.url =
      this.apiUrl +
      `world/category/${url}?postal_code=${postalCode}&radius=${radius}&filter[keyword]=${keyword}&sort=${sort}title`;
    return this.http.get(this.url);
  }

  public postLead(req) {
    this.url = this.apiUrl + "lead";
    return this.http.post(this.url, req);
  }

  public getIndustrys() {
    this.url = this.apiUrl + "industrys";
    return this.http.get(this.url);
  }

  public getIndustry(url) {
    this.url = this.apiUrl + `industry/${url}`;
    return this.http.get(this.url);
  }

  /** provider **/
  public getProvidersPremium() {
    this.url = this.apiUrl + "providers/premium";
    return this.http.get(this.url);
  }

  public getProviders(
    postalCode = "",
    radius = "",
    keyword = "",
    industrys = [],
    prefix = "",
    sort = "",
    pageSize = "",
    page = ""
  ) {
    this.url =
      this.apiUrl +
      `providers?postal_code=${postalCode}&radius=${radius}&filter[keyword]=${keyword}&industrys=${JSON.stringify(
        industrys
      )}&filter[prefix]=${prefix}&sort=${sort}company_name&per_page=${pageSize}&page=${page}`;
    return this.http.get(this.url);
  }

  public getProvider(url) {
    this.url = this.apiUrl + `provider/${url}`;
    return this.http.get(this.url);
  }

  public postProviderAppointment(req) {
    this.url = this.apiUrl + "provider/appointment";
    return this.http.post(this.url, req);
  }

  /** portal **/
  public getPortalCategorys(keyword = "") {
    this.url = this.apiUrl + `portal/categorys?filter[keyword]=${keyword}`;
    return this.http.get(this.url);
  }

  public getPortalCategory(url, keyword = "") {
    this.url =
      this.apiUrl + `portal/category/${url}?filter[keyword]=${keyword}`;
    return this.http.get(this.url);
  }

  public getPortalPost(url, keyword = "") {
    this.url = this.apiUrl + `portal/post/${url}?filter[keyword]=${keyword}`;
    return this.http.get(this.url);
  }

  /** shop **/

  /** account **/
  public getLeadsCustomer() {
    this.url = this.apiUrl + "leads/customer";
    return this.http.get(this.url);
  }

  public getLeadsProvider() {
    this.url = this.apiUrl + "leads/provider";
    return this.http.get(this.url);
  }

  public getLeadCusomter(id) {
    this.url = this.apiUrl + `lead/customer?lead_id=${id}`;
    return this.http.get(this.url);
  }

  public getLeadProvider(id) {
    this.url = this.apiUrl + `lead/provider?lead_id=${id}`;
    return this.http.get(this.url);
  }

  public getCustomerDocuments() {
    this.url = this.apiUrl + "inquiry/customer/documents";
    return this.http.get(this.url);
  }
  public getDocuments() {
    this.url = this.apiUrl + "inquiry/documents";
    return this.http.get(this.url);
  }

  public postInquiryCustomerStatus(req) {
    this.url = this.apiUrl + "inquiry/customer/status";
    return this.http.post(this.url, req);
  }

  public postInquiryCustomerContact(req) {
    this.url = this.apiUrl + "inquiry/customer/contact";
    return this.http.post(this.url, req);
  }

  public postInquiryProvider(req) {
    this.url = this.apiUrl + "inquiry/provider";
    return this.http.post(this.url, req);
  }

  public getProfileCustomer() {
    this.url = this.apiUrl + "profile/customer";
    return this.http.get(this.url);
  }

  public getProfileProvider() {
    this.url = this.apiUrl + "profile/provider";
    return this.http.get(this.url);
  }

  public postProfileCustomer(req) {
    this.url = this.apiUrl + "profile/customer";
    return this.http.post(this.url, req);
  }

  public postProfileProvider(req) {
    this.url = this.apiUrl + "profile/provider";
    return this.http.post(this.url, req);
  }

  /** vr payment **/
  public postVrp(req) {
    this.url = this.apiUrl + "vrpayment/checkout";
    return this.http.post(this.url, req);
  }

  public getVrp(checkoutId) {
    this.url = this.apiUrl + `vrpayment/checkout?checkout_id=${checkoutId}`;
    return this.http.get(this.url);
  }

  /** contact **/
  public postContact(req) {
    this.url = this.apiUrl + "contact";
    return this.http.post(this.url, req);
  }

  /** site content **/
  public getSiteContents() {
    this.url = this.apiUrl + "site_contents";
    return this.http.get(this.url);
  }

  /** Service Sliders **/
  public getServiceSliders() {
    this.url = this.apiUrl + "service_sliders";
    return this.http.get(this.url);
  }

  /** Online Shop */
  public getShopPrimaryCategories() {
    this.url = this.apiUrl + "shop/categorys/primary";
    return this.http.get(this.url);
  }

  public getShopAllProducts() {
    this.url = this.apiUrl + "shop/search";
    return this.http.get(this.url);
  }

  public getShopRecommendedProducts() {
    this.url = this.apiUrl + "shop/products/recommend";
    return this.http.get(this.url);
  }

  public getShopRecommendedCategorys() {
    this.url = this.apiUrl + "shop/categorys/recommend";
    return this.http.get(this.url);
  }

  public getProductDetail(productId) {
    this.url = this.apiUrl + `shop/product_detail?product_id=${productId}`;
    return this.http.get(this.url);
  }

  public addProductToCart(product_id, quantity, cartId) {
    var req = new FormData();
    req.append("product_id", product_id);
    req.append("quantity", quantity);
    this.url = this.apiUrl + `shop/cart`;
    if (cartId) {
      req.append("cart_key", cartId);
    }
    return this.http.post(this.url, req);
  }

  public getProductsFromCart(cartId) {
    this.url = this.apiUrl + `shop/cart/show?cart_key=${cartId}`;
    return this.http.get(this.url);
  }
}
