import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-category-category",
  templateUrl: "./category-category.component.html",
  styleUrls: ["./category-category.component.scss"],
})
export class CategoryCategoryComponent implements OnInit {
  category: any = [];

  postalCode: any = "";
  radius: any = "";
  keyword: any = "";
  sort: any = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.getCategory();
  }

  zone(event) {
    this.postalCode = event.postalCode;
    this.radius = event.radius;
  }

  search(event) {
    this.keyword = event.keyword;

    this.getCategory();
  }

  direct(event) {
    this.sort = event.sort;

    this.getCategory();
  }

  getCategory() {
    var url = this.route.snapshot.params["url"];

    this.apiService
      .getWorldCategory(
        url,
        this.postalCode,
        this.radius,
        this.keyword,
        this.sort
      )
      .subscribe(
        (res) => {
          this.category = res["data"];
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
