import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { UtilService } from "../../../../../_services/util.service";

@Component({
  selector: "app-lead-single-daterange",
  templateUrl: "./lead-single-daterange.component.html",
  styleUrls: ["./lead-single-daterange.component.scss"],
})
export class LeadSingleDaterangeComponent implements OnInit {
  @Input() question;
  @Input() savedAnswer;
  @Output("answeringE") answeringE: EventEmitter<any> = new EventEmitter();

  activeAnswers: Array<any> = [];
  today: any = null;

  constructor(private utilService: UtilService) {
    this.today = this.utilService.getToday();
  }

  ngOnInit() {
    if (this.savedAnswer) {
      this.activeAnswers = this.savedAnswer;
    } else {
      setTimeout(() => {
        this.answering([this.today, this.today], "all");
      }, 10);
    }
  }

  answering(answer, index) {
    if (index == "all") this.activeAnswers = answer;
    else this.activeAnswers[index] = answer;

    this.answeringE.emit({
      question: this.question.id,
      answer: this.activeAnswers,
      type: "single-daterange",
      position: this.question.position,
      next: this.question.answers[0].overwrite_next_question_pos,
    });
  }
}
