import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category5",
  templateUrl: "./category5.component.html",
  styleUrls: ["./category5.component.scss"],
})
export class Category5Component implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  registerProvider() {
    this.router.navigateByUrl("/register-provider");
  }
}
