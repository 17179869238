import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-category-lead1",
  templateUrl: "./category-lead1.component.html",
  styleUrls: ["./category-lead1.component.scss"],
})
export class CategoryLead1Component implements OnInit {
  @Input() category;

  constructor() {}

  ngOnInit() {}
}
