import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-shipping2",
  templateUrl: "./cart-shipping2.component.html",
  styleUrls: ["./cart-shipping2.component.scss"],
})
export class CartShipping2Component implements OnInit {
  shippings: Array<any> = [
    {
      name: "dhl",
      image: "assets/img/cart-shipping-dhl.png",
      time: [1, 3],
      cost: 0,
    },
    {
      name: "dpd",
      image: "assets/img/cart-shipping-dpd.png",
      time: [2, 3],
      cost: 4.99,
    },
    {
      name: "ups",
      image: "assets/img/cart-shipping-ups.png",
      time: [1, 2],
      cost: 8.99,
    },
    {
      name: "ups-express",
      image: "assets/img/cart-shipping-ups-express.png",
      time: [1],
      cost: 18.99,
    },
  ];

  constructor() {}

  ngOnInit() {}
}
