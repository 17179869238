import { Routes } from "@angular/router";

import { CategoryComponent } from "./category/category.component";
import { CategoryViewComponent } from "./category-view/category-view.component";
import { CategorySuperComponent } from "./category-super/category-super.component";
import { CategorySimpleComponent } from "./category-simple/category-simple.component";

export const PortalRoutes: Routes = [
  {
    path: "portal-category",
    children: [
      {
        path: "",
        component: CategoryComponent,
      },
      {
        path: "view/:url",
        component: CategoryViewComponent,
      },
      {
        path: "super/:url",
        component: CategorySuperComponent,
      },
      {
        path: "simple/:url",
        component: CategorySimpleComponent,
      },
    ],
  },
];
