import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-address",
  templateUrl: "./cart-address.component.html",
  styleUrls: ["./cart-address.component.scss"],
})
export class CartAddressComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
