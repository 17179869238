import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-privacy1",
  templateUrl: "./privacy1.component.html",
  styleUrls: ["./privacy1.component.scss"],
})
export class Privacy1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
