import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-customer-news",
  templateUrl: "./customer-news.component.html",
  styleUrls: ["./customer-news.component.scss"],
})
export class CustomerNewsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
