import { Component, OnInit } from "@angular/core";
import country from "country-list";

@Component({
  selector: "app-cart-address2",
  templateUrl: "./cart-address2.component.html",
  styleUrls: ["./cart-address2.component.scss"],
})
export class CartAddress2Component implements OnInit {
  countrys: Array<any> = [];

  constructor() {}

  ngOnInit() {
    this.countrys = Object.keys(country.getNameList());
  }
}
