import { Routes } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { RemindPasswordComponent } from "./remind-password/remind-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RegisterCustomerComponent } from "./register-customer/register-customer.component";
import { RegisterProviderComponent } from "./register-provider/register-provider.component";
import { RegisterProviderSuccessComponent } from "./register-provider-success/register-provider-success.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";

export const AuthRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "remind-password",
        component: RemindPasswordComponent,
      },
      {
        path: "reset-password",
        component: ResetPasswordComponent,
      },
      {
        path: "register-customer",
        component: RegisterCustomerComponent,
      },
      {
        path: "register-provider",
        component: RegisterProviderComponent,
      },
      {
        path: "register-provider-success",
        component: RegisterProviderSuccessComponent,
      },
      {
        path: "verify-email",
        component: VerifyEmailComponent,
      },
    ],
  },
];
