import { Component, OnChanges, Input } from "@angular/core";

@Component({
  selector: "app-category-view4",
  templateUrl: "./category-view4.component.html",
  styleUrls: ["./category-view4.component.scss"],
})
export class CategoryView4Component implements OnChanges {
  @Input() category;

  categorys: Array<any> = [];

  constructor() {}

  ngOnChanges() {
    this.categorys = this.category.posts;
  }
}
