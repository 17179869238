import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-coming-soon",
  templateUrl: "./coming-soon.component.html",
  styleUrls: ["./coming-soon.component.scss"],
})
export class ComingSoonComponent implements OnInit {
  height: any = 0;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.height = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
    }, 1000);
  }
}
