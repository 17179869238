import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { TreeModule } from "@circlon/angular-tree-component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgmCoreModule } from "@agm/core";
import { NgcCookieConsentModule } from "ngx-cookieconsent";
import { NgxGalleryModule } from "@kolkov/ngx-gallery";
import { AppRoutingModule } from "./app.routing";
import { VrModule } from "./vr/vr.module";

import { AuthModule } from "./pages/auth/auth.module";
import { WorldModule } from "./pages/world/world.module";
import { ProviderModule } from "./pages/provider/provider.module";
import { PortalModule } from "./pages/portal/portal.module";
import { ShopModule } from "./pages/shop/shop.module";
import { AccountModule } from "./pages/account/account.module";
import { CartModule } from "./pages/cart/cart.module";
import { HelpModule } from "./pages/help/help.module";

import { AppComponent } from "./app.component";
import { SidebarComponent } from "./shared/sidebar/sidebar.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { AuthLayoutComponent } from "./layout/auth/auth-layout.component";
import { MainLayoutComponent } from "./layout/main/main-layout.component";

import { AuthService } from "./_services/auth.service";
import { ApiService } from "./_services/api.service";
import { StorageService } from "./_services/storage.service";
import { UtilService } from "./_services/util.service";
import { UploadService } from "./_services/upload.service";
import { AuthGuard } from "./_guards/auth.guard";
import { RoleGuard } from "./_guards/role.guard";
import { SubscribeGuard } from "./_guards/subscribe.guard";
import { UnsubscribeGuard } from "./_guards/unsubcribe.guard";
import { TokenInterceptor } from "./_interceptors/token.interceptor";

import { environment } from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    TreeModule,
    NgbModule,
    RouterModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAfr_n8oaywrdwM18-eD8QQvcqmpnkxCMQ",
    }),
    NgcCookieConsentModule.forRoot({
      cookie: {
        domain: environment.cookieDomain,
      },
      palette: {
        popup: {
          background: "#004ba8",
        },
        button: {
          background: "#fa6705",
        },
      },
      content: {
        message:
          "Unsere Website benutzt Cookies und Website-Analyse-Tools. Durch die Nutzung unserer Website erklären Sie sich damit einverstanden. In unserer Datenschutzerklärung finden Sie Informationen und Links zur Deaktivierung dieser Maßnahmen.",
        link: "Zur Datenschutzerklärung.",
        href: "./#/privacy",
        dismiss: "Verstanden",
      },
      theme: "classic",
    }),
    NgxGalleryModule,
    AppRoutingModule,
    VrModule,

    AuthModule,
    WorldModule,
    ProviderModule,
    PortalModule,
    ShopModule,
    AccountModule,
    CartModule,
    HelpModule,
  ],
  exports: [],
  providers: [
    AuthService,
    ApiService,
    StorageService,
    UtilService,
    UploadService,
    AuthGuard,
    RoleGuard,
    SubscribeGuard,
    UnsubscribeGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
