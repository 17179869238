import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-imprint1",
  templateUrl: "./imprint1.component.html",
  styleUrls: ["./imprint1.component.scss"],
})
export class Imprint1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
