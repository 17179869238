import { Component, OnInit } from "@angular/core";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  privacy: any = null;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getSiteContents().subscribe(
      (res) => {
        this.privacy = res["data"]["privacy"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
