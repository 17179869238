import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-lead-single-range",
  templateUrl: "./lead-single-range.component.html",
  styleUrls: ["./lead-single-range.component.scss"],
})
export class LeadSingleRangeComponent implements OnInit {
  @Input() question;
  @Input() savedAnswer;
  @Output("answeringE") answeringE: EventEmitter<any> = new EventEmitter();

  activeAnswer: any = 0;

  constructor() {}

  ngOnInit() {
    if (this.savedAnswer) {
      this.activeAnswer = this.savedAnswer;
    } else {
      setTimeout(() => {
        this.answering(
          this.question.answers[0].range_default
            ? this.question.answers[0].range_default
            : this.question.answers[0].range_min
        );
      }, 100);
    }
  }

  answering(answer) {
    this.activeAnswer = answer;

    this.answeringE.emit({
      question: this.question.id,
      answer: parseFloat(this.activeAnswer),
      type: "single-range",
      position: this.question.position,
      next: this.question.answers[0].overwrite_next_question_pos,
    });
  }
}
