import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart1",
  templateUrl: "./cart1.component.html",
  styleUrls: ["./cart1.component.scss"],
})
export class Cart1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
