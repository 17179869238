import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-category-category2",
  templateUrl: "./category-category2.component.html",
  styleUrls: ["./category-category2.component.scss"],
})
export class CategoryCategory2Component implements OnInit {
  @Output("searchE") searchE: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  search(event) {
    this.searchE.emit(event);
  }
}
