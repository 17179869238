import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../_services/api.service";
import { UtilService } from "../../../../_services/util.service";
@Component({
  selector: "app-customer-document",
  templateUrl: "./customer-document.component.html",
  styleUrls: ["./customer-document.component.scss"],
})
export class CustomerDocumentComponent implements OnInit {
  documents: Array<any> = [];
  constructor(
    private apiService: ApiService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.apiService.getCustomerDocuments().subscribe(
      (res) => {
        this.documents = res["data"];
        this.documents = this.documents.map((document) => {
          document.date =
            this.utilService.getDateObj(document.date).day +
            "." +
            this.utilService.getDateObj(document.date).month +
            "." +
            this.utilService.getDateObj(document.date).year;
          return document;
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
