import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ShopRoutes } from "./shop.routing";
import { VrModule } from "../../vr/vr.module";

import { ShopComponent } from "./shop/shop.component";
import { Shop1Component } from "./shop/sections/shop1/shop1.component";
import { Shop2Component } from "./shop/sections/shop2/shop2.component";
import { Shop3Component } from "./shop/sections/shop3/shop3.component";
import { Shop4Component } from "./shop/sections/shop4/shop4.component";
import { Shop5Component } from "./shop/sections/shop5/shop5.component";

import { ShopViewComponent } from "./shop-view/shop-view.component";
import { ShopView1Component } from "./shop-view/sections/shop-view1/shop-view1.component";
import { ShopView2Component } from "./shop-view/sections/shop-view2/shop-view2.component";
import { ShopView3Component } from "./shop-view/sections/shop-view3/shop-view3.component";
import { ShopView4Component } from "./shop-view/sections/shop-view4/shop-view4.component";

import { ProductComponent } from "./product/product.component";
import { ProductViewComponent } from "./product/sections/product-view/product-view.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ShopRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    VrModule,
  ],
  declarations: [
    ShopComponent,
    Shop1Component,
    Shop2Component,
    Shop3Component,
    Shop4Component,
    Shop5Component,

    ShopViewComponent,
    ShopView1Component,
    ShopView2Component,
    ShopView3Component,
    ShopView4Component,

    ProductComponent,
    ProductViewComponent
  ],
})
export class ShopModule {}
