import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { AuthService } from "../../../_services/auth.service";
import { UtilService } from "../../../_services/util.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup = null;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private authService: AuthService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.resetForm = this.formBuilder.group(
      {
        password: ["", [Validators.required, Validators.minLength(6)]],
        passwordConf: ["", Validators.required],
      },
      {
        validators: [this.utilService.mustMatch("password", "passwordConf")],
      }
    );
  }

  get resetF() {
    return this.resetForm.controls;
  }

  reset() {
    this.route.queryParams.subscribe((params) => {
      this.submitted = true;

      if (this.resetForm.valid) {
        var req = {
          password: this.resetF["password"].value,
          token: params["token"],
        };

        this.authService.resetPassword(req).subscribe(
          (res) => {
            this.notifierService.notify("success", "Password reset success.");
          },
          (err) => {
            console.log(err);
            this.notifierService.notify("error", err.error.message);
          }
        );
      }
    });
  }
}
