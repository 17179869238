import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-lead-single-text",
  templateUrl: "./lead-single-text.component.html",
  styleUrls: ["./lead-single-text.component.scss"],
})
export class LeadSingleTextComponent implements OnInit {
  @Input() question;
  @Input() savedAnswer;
  @Output("answeringE") answeringE: EventEmitter<any> = new EventEmitter();

  activeAnswer: any = "";

  constructor() {}

  ngOnInit() {
    if (this.savedAnswer) this.activeAnswer = this.savedAnswer;
    else this.activeAnswer = "";
  }

  answering(answer) {
    this.activeAnswer = answer;

    this.answeringE.emit({
      question: this.question.id,
      answer: this.activeAnswer,
      type: "single-text",
      position: this.question.position,
      next: this.question.answers[0].overwrite_next_question_pos,
    });
  }
}
