import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../../../_services/api.service";

@Component({
  selector: "app-category6",
  templateUrl: "./category6.component.html",
  styleUrls: ["./category6.component.scss"],
})
export class Category6Component implements OnInit {
  buttons: Array<any> = [];

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getServiceSliders().subscribe(
      (res) => {
        this.buttons = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
  openUrl(url) {
    window.open(url, "_self");
  }
}
