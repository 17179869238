import { Component, OnInit } from "@angular/core";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {
  worlds: Array<any> = [];
  providers: Array<any> = [];
  rProducts: Array<any> = [];

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getWorlds().subscribe(
      (res) => {
        this.worlds = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );

    this.apiService.getProvidersPremium().subscribe(
      (res) => {
        this.providers = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );

    this.rProducts = [
      {
        image: "assets/img/recommend-product-1.jpg",
        text: "Bosch Smart Home Rauchmelder weiß",
        price: 58.48,
      },
      {
        image: "assets/img/recommend-product-2.jpg",
        text: "Bosch Smart Home - Starter Set Heizung mit 4 Thermostaten",
        price: 299,
      },
      {
        image: "assets/img/recommend-product-3.jpg",
        text: "Smart Lock Fingerabdruckschloss",
        price: 93.84,
      },
      {
        image: "assets/img/recommend-product-4.jpg",
        text: "SENEC.HOME V2.1 Stromspeicher",
        price: 7128.1,
      },
      {
        image: "assets/img/recommend-product-5.jpg",
        text:
          "5000 Watt Solaranlage Hybridset einphasig mit 6 KWh Solarspeicher",
        price: 3828.34,
      },
      {
        image: "assets/img/recommend-product-6.jpg",
        text:
          "Heidelberg Wallbox Home Eco 11kW Ladestation für Elektro- und Hybridfahrzeuge",
        price: 364.61,
      },
      {
        image: "assets/img/recommend-product-7.jpg",
        text: "TAN Generator für Smart-TAN",
        price: 19.4,
      },
      {
        image: "assets/img/recommend-product-8.jpg",
        text: "Bonrollen / Thermopapier für EC-Cash, 50 Stk.",
        price: 23.0,
      },
      {
        image: "assets/img/recommend-product-9.jpg",
        text: "Verifone H5000 / Touchscreen",
        price: 6.9,
      },
      {
        image: "assets/img/recommend-product-10.png",
        text: "Olympia Münzrollen 3701 Farbig sortiert 8 Stück",
        price: 17.99,
      },
      {
        image: "assets/img/recommend-product-11.jpeg",
        text: "Drohnenaufnahmen",
        price: 99.0,
      },
      {
        image: "assets/img/recommend-product-12.jpg",
        text: "6 Bio-Sonneneier (L/M)",
        price: 2.99,
      },
      {
        image: "assets/img/recommend-product-13.jpg",
        text: "Bio-Band Nudeln",
        price: 2.99,
      },
      {
        image: "assets/img/recommend-product-14.jpg",
        text: "Grobe hausmacher Bratwürstchen",
        price: 0.69,
      },
    ];
  }
}
