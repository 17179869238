import { Routes } from "@angular/router";

import { FaqCustomerComponent } from "./faq-customer/faq-customer.component";
import { FaqProviderComponent } from "./faq-provider/faq-provider.component";
import { TosCustomerComponent } from "./tos-customer/tos-customer.component";
import { TosProviderComponent } from "./tos-provider/tos-provider.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { ImprintComponent } from "./imprint/imprint.component";
import { ContactComponent } from "./contact/contact.component";
import { SupportComponent } from "./support/support.component";

export const HelpRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "faq-customer",
        component: FaqCustomerComponent,
      },
      {
        path: "faq-provider",
        component: FaqProviderComponent,
      },
      {
        path: "tos-customer",
        component: TosCustomerComponent,
      },
      {
        path: "tos-provider",
        component: TosProviderComponent,
      },
      {
        path: "privacy",
        component: PrivacyComponent,
      },
      {
        path: "imprint",
        component: ImprintComponent,
      },
      {
        path: "contact",
        component: ContactComponent,
      },
      {
        path: "support",
        component: SupportComponent,
      },
    ],
  },
];
