import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ITreeOptions } from "@circlon/angular-tree-component";
import { Router } from "@angular/router";

@Component({
  selector: "app-provider3",
  templateUrl: "./provider3.component.html",
  styleUrls: ["./provider3.component.scss"],
})
export class Provider3Component implements OnChanges {
  @Input() industrysAll;
  @Input() prefixs;
  @Input() providers;
  @Input() collectionSize;
  @Output("controlE") controlE: EventEmitter<any> = new EventEmitter();

  filterOpen: boolean = false;

  industrys: Array<any> = [];
  prefix: any = "";
  sort: any = "";
  pageSize: any = 16;
  page: any = 1;

  constructor(private router: Router) {}

  nodes = [
    // {
    //   name: "root1",
    // },
    // {
    //   name: "root2",
    //   children: [
    //     { name: "child1" },
    //     {
    //       name: "child2",
    //       children: [{ name: "grandchild1" }, { name: "grandchild2" }],
    //     },
    //   ],
    // },
  ];

  options: ITreeOptions = {
    useCheckbox: true,
  };

  ngOnChanges() {}

  ngOnInit() {
    console.log("industryal", this.industrysAll);
    this.nodes = this.industrysAll;
  }
  onEvent(event) {
    const { eventName, node } = event;
    console.log(event);
    if (eventName === "select") {
      this.industrys.push(node.data.id);
      this.control();
    } else if (eventName === "deselect") {
      this.industrys.splice(this.industrys.indexOf(node.data.id), 1);
      this.control();
    }
    this.filterOpen = true;
  }

  filterCategory(category) {
    this.industrys = [category.id];
    this.control();
  }

  changeIndustrys(industry, checked) {
    if (checked) this.industrys.push(industry.id);
    else this.industrys.splice(this.industrys.indexOf(industry.id), 1);

    this.control();
  }

  changePrefix(prefix) {
    if (prefix == "ALLE") this.prefix = "";
    else this.prefix = prefix;

    this.control();
  }

  changeSort(sort) {
    this.sort = sort;

    this.control();
  }

  pageChange(page) {
    this.page = page;

    this.control();
  }

  control() {
    this.controlE.emit({
      industrys: this.industrys,
      prefix: this.prefix,
      sort: this.sort,
      pageSize: this.pageSize,
      page: this.page,
    });
  }

  providerView(provider) {
    this.router.navigateByUrl(`/provider/view/${provider.url}`);
  }
}
