import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-category-contacting",
  templateUrl: "./category-contacting.component.html",
  styleUrls: ["./category-contacting.component.scss"],
})
export class CategoryContactingComponent implements OnInit {
  industry: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    var url = this.route.snapshot.params["url"];

    this.apiService.getIndustry(url).subscribe(
      (res) => {
        this.industry = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
