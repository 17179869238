import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-category-contact1",
  templateUrl: "./category-contact1.component.html",
  styleUrls: ["./category-contact1.component.scss"],
})
export class CategoryContact1Component implements OnInit {
  @Input() category;

  constructor() {}

  ngOnInit() {}
}
