import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-provider4",
  templateUrl: "./provider4.component.html",
  styleUrls: ["./provider4.component.scss"],
})
export class Provider4Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
