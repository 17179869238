import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import {
  Router,
} from "@angular/router";
import { ApiService } from "../../../../../_services/api.service";

@Component({
  selector: "app-contact2",
  templateUrl: "./contact2.component.html",
  styleUrls: ["./contact2.component.scss"],
})
export class Contact2Component implements OnInit {
  contactForm: FormGroup = null;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      company: [""],
      phone: [""],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      file: [""],
    });
  }

  get contactF() {
    return this.contactForm.controls;
  }

  changeFile(event) {
    var reader = new FileReader(),
      file;

    if (event.target.files && event.target.files.length) {
      reader.onload = () => {
        file = event.target.files[0];

        if (file.size > 1000000) {
          this.contactF.file.setErrors({ exceedSize: true });
        } else {
          this.contactF.file.setErrors(null);
          this.contactF.file.setValue(event.target.files[0]);
        }
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  contact() {
    this.submitted = true;

    if (this.contactForm.valid) {
      var req = new FormData();

      req.append("first_name", this.contactF["firstName"].value);
      req.append("last_name", this.contactF["lastName"].value);
      req.append("email", this.contactF["email"].value);
      req.append("company", this.contactF["company"].value);
      req.append("phone", this.contactF["phone"].value);
      req.append("subject", this.contactF["subject"].value);
      req.append("message", this.contactF["message"].value);
      req.append("file", this.contactF["file"].value);

      this.apiService.postContact(req).subscribe(
        (res) => {
          console.log(res);
          this.notifierService.notify("success", "Kontaktanfrage erfolgreich");
          setTimeout(() => {
            this.router.navigateByUrl("/");
          }, 2000);
          // this.notifierService.notify("success", "Contact success.");
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    }
  }
}
