import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { PortalRoutes } from "./portal.routing";
import { VrModule } from "../../vr/vr.module";

import { CategoryComponent } from "./category/category.component";
import { Category1Component } from "./category/sections/category1/category1.component";
import { Category2Component } from "./category/sections/category2/category2.component";
import { Category3Component } from "./category/sections/category3/category3.component";

import { CategoryViewComponent } from "./category-view/category-view.component";
import { CategoryView1Component } from "./category-view/sections/category-view1/category-view1.component";
import { CategoryView2Component } from "./category-view/sections/category-view2/category-view2.component";
import { CategoryView3Component } from "./category-view/sections/category-view3/category-view3.component";
import { CategoryView4Component } from "./category-view/sections/category-view4/category-view4.component";

import { CategorySuperComponent } from "./category-super/category-super.component";
import { CategorySuper1Component } from "./category-super/sections/category-super1/category-super1.component";
import { CategorySuper2Component } from "./category-super/sections/category-super2/category-super2.component";
import { CategorySuper3Component } from "./category-super/sections/category-super3/category-super3.component";
import { CategorySuper4Component } from "./category-super/sections/category-super4/category-super4.component";

import { CategorySimpleComponent } from "./category-simple/category-simple.component";
import { CategorySimple1Component } from "./category-simple/sections/category-simple1/category-simple1.component";
import { CategorySimple2Component } from "./category-simple/sections/category-simple2/category-simple2.component";
import { CategorySimple3Component } from "./category-simple/sections/category-simple3/category-simple3.component";

import { PortalCategoryComponent } from "./_common/portal-category/portal-category.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(PortalRoutes),
    NgbModule,
    VrModule,
  ],
  declarations: [
    CategoryComponent,
    Category1Component,
    Category2Component,
    Category3Component,

    CategoryViewComponent,
    CategoryView1Component,
    CategoryView2Component,
    CategoryView3Component,
    CategoryView4Component,

    CategorySuperComponent,
    CategorySuper1Component,
    CategorySuper2Component,
    CategorySuper3Component,
    CategorySuper4Component,

    CategorySimpleComponent,
    CategorySimple1Component,
    CategorySimple2Component,
    CategorySimple3Component,

    PortalCategoryComponent,
  ],
})
export class PortalModule {}
