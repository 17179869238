import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-category9",
  templateUrl: "./category9.component.html",
  styleUrls: ["./category9.component.scss"],
})
export class Category9Component implements OnInit {
  @Input() rProducts;

  constructor() {}

  ngOnInit() {}
}
