import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { AuthService } from "../../../_services/auth.service";

@Component({
  selector: "app-register-provider",
  templateUrl: "./register-provider.component.html",
  styleUrls: ["./register-provider.component.scss"],
})
export class RegisterProviderComponent implements OnInit {
  registerForm: FormGroup = null;
  submitted: boolean = false;

  gender: any = "male";
  agreed: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notifierService: NotifierService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      companyName: ["", Validators.required],
      street: ["", Validators.required],
      streetNumber: ["", Validators.required],
      postalCode: ["", Validators.required],
      city: ["", Validators.required],
      phone: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/
          ),
        ],
      ],
      email: ["", [Validators.required, Validators.email]],
    });
  }

  get registerF() {
    return this.registerForm.controls;
  }

  changeGender(gender) {
    this.gender = gender;
  }

  changeAgreed(agreed) {
    this.agreed = agreed;
  }

  register() {
    this.submitted = true;

    if (this.registerForm.valid) {
      var req = {
        gender: this.gender,
        first_name: this.registerF["firstName"].value,
        last_name: this.registerF["lastName"].value,
        company_name: this.registerF["companyName"].value,
        street: this.registerF["street"].value,
        street_number: this.registerF["streetNumber"].value,
        postal_code: this.registerF["postalCode"].value,
        city: this.registerF["city"].value,
        phone: this.registerF["phone"].value,
        email: this.registerF["email"].value,
      };

      if (this.agreed) {
        this.authService.registerProvider(req).subscribe(
          (res) => {
            this.router.navigateByUrl("/register-provider-success");
          },
          (err) => {
            console.log(err);
            this.notifierService.notify("error", err.error.message);
          }
        );
      }
    }
  }
}
