import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TagInputModule } from "ngx-chips";
import { NotifierModule } from "angular-notifier";

import { AccountRoutes } from "./account.routing";
import { VrModule } from "../../vr/vr.module";

import { CustomerLeadComponent } from "./customer/customer-lead/customer-lead.component";
import { CustomerLeadViewComponent } from "./customer/customer-lead-view/customer-lead-view.component";
import { CustomerProfileComponent } from "./customer/customer-profile/customer-profile.component";
/** coming soon **/
import { CustomerDashboardComponent } from "./customer/customer-dashboard/customer-dashboard.component";
import { CustomerNewsComponent } from "./customer/customer-news/customer-news.component";
import { CustomerShopComponent } from "./customer/customer-shop/customer-shop.component";
import { CustomerFavoriteComponent } from "./customer/customer-favorite/customer-favorite.component";
import { CustomerDocumentComponent } from "./customer/customer-document/customer-document.component";
import { CustomerFinancingComponent } from "./customer/customer-financing/customer-financing.component";

import { ProviderJoinComponent } from "./provider/provider-join/provider-join.component";
import { ProviderJoinFormComponent } from "./provider/provider-join-form/provider-join-form.component";
import { ProviderJoinFormingComponent } from "./provider/provider-join-forming/provider-join-forming.component";
import { ProviderLeadComponent } from "./provider/provider-lead/provider-lead.component";
import { ProviderLeadViewComponent } from "./provider/provider-lead-view/provider-lead-view.component";
import { ProviderProfileComponent } from "./provider/provider-profile/provider-profile.component";
/** coming soon **/
import { ProviderDashboardComponent } from "./provider/provider-dashboard/provider-dashboard.component";
import { ProviderNewsComponent } from "./provider/provider-news/provider-news.component";
import { ProviderShopComponent } from "./provider/provider-shop/provider-shop.component";
import { ProviderFavoriteComponent } from "./provider/provider-favorite/provider-favorite.component";
import { ProviderDocumentComponent } from "./provider/provider-document/provider-document.component";
import { ProviderFinancingComponent } from "./provider/provider-financing/provider-financing.component";

import { LayoutComponent } from "./_layout/layout.component";
import { Layout1Component } from "./_layout/sections/layout1/layout1.component";
import { Layout2Component } from "./_layout/sections/layout2/layout2.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AccountRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    NotifierModule,
    VrModule,
  ],
  declarations: [
    CustomerLeadComponent,
    CustomerLeadViewComponent,
    CustomerProfileComponent,
    /** coming soon **/
    CustomerDashboardComponent,
    CustomerNewsComponent,
    CustomerShopComponent,
    CustomerFavoriteComponent,
    CustomerDocumentComponent,
    CustomerFinancingComponent,

    ProviderJoinComponent,
    ProviderJoinFormComponent,
    ProviderJoinFormingComponent,
    ProviderLeadComponent,
    ProviderLeadViewComponent,
    ProviderProfileComponent,
    /** coming soon **/
    ProviderDashboardComponent,
    ProviderNewsComponent,
    ProviderShopComponent,
    ProviderFavoriteComponent,
    ProviderDocumentComponent,
    ProviderFinancingComponent,

    LayoutComponent,
    Layout1Component,
    Layout2Component,
  ],
})
export class AccountModule {}
