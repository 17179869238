import { Component, OnInit } from "@angular/core";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-tos-provider",
  templateUrl: "./tos-provider.component.html",
  styleUrls: ["./tos-provider.component.scss"],
})
export class TosProviderComponent implements OnInit {
  tosProvider: any = null;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getSiteContents().subscribe(
      (res) => {
        this.tosProvider = res["data"]["tos_provider"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
