import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-provider-financing",
  templateUrl: "./provider-financing.component.html",
  styleUrls: ["./provider-financing.component.scss"],
})
export class ProviderFinancingComponent implements OnInit {
  iframe_url: any =
    "https://angebot.easycredit.de/ece/pb/informieren?pk=1.de.717.9999";
  constructor() {}

  ngOnInit() {}
}
