import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../../_services/api.service";

@Component({
  selector: "app-provider-join-form",
  templateUrl: "./provider-join-form.component.html",
  styleUrls: ["./provider-join-form.component.scss"],
})
export class ProviderJoinFormComponent implements OnInit {
  brand: any = "";
  checkoutId: any = "";
  actionUrl: any = location.origin + "/#/account/provider-join/forming";

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit() {
    this.brand = this.route.snapshot.queryParamMap.get("brand");

    var req = {
      brand: this.brand,
    };

    this.apiService.postVrp(req).subscribe(
      (res) => {
        this.checkoutId = res["data"]["checkout_id"];

        this.loadScript();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script");

    for (var i = 0; i < scripts.length; ++i) {
      if (
        scripts[i].getAttribute("src") != null &&
        scripts[i].getAttribute("src").includes("paymentWidgets.js")
      ) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = [
        `https://vr-pay-ecommerce.de/v1/paymentWidgets.js?checkoutId=${this.checkoutId}`,
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement("script");
        node.src = dynamicScripts[i];
        node.type = "text/javascript";
        node.async = false;
        node.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(node);
      }
    }
  }

  changeBrand(brand) {
    window.location.href = `/#/account/provider-join/form?brand=${brand}`;
    window.location.reload();
  }
}
