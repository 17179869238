import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-shop3",
  templateUrl: "./shop3.component.html",
  styleUrls: ["./shop3.component.scss"],
})
export class Shop3Component implements OnInit {
  @Input() rProducts;

  constructor() {}

  ngOnInit() {}
}
