import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { StorageService } from "../../../../_services/storage.service";

@Component({
  selector: "app-provider-join",
  templateUrl: "./provider-join.component.html",
  styleUrls: ["./provider-join.component.scss"],
})
export class ProviderJoinComponent implements OnInit {
  paymentwall: any = 0;

  constructor(private router: Router, private storageService: StorageService) {}

  ngOnInit() {
    this.paymentwall = this.storageService.get("paymentwall");
  }

  accountProviderJoinForm() {
    window.location.href = "/#/account/provider-join/form?brand=VISA";
    window.location.reload();
  }

  faqProvider() {
    this.router.navigateByUrl("faq-provider");
  }
}
