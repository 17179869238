import { Component, OnInit, Input } from "@angular/core";

import { AuthService } from "../../../../../_services/auth.service";
import { ApiService } from "../../../../../_services/api.service";
@Component({
  selector: "app-layout2",
  templateUrl: "./layout2.component.html",
  styleUrls: ["./layout2.component.scss"],
})
export class Layout2Component implements OnInit {
  @Input() role;
  @Input() name;
  @Input() subscribed;
  avatar: any = "";
  menusC: Array<any> = [
    // {
    //   link: "/account/customer-dashboard",
    //   text: "Dashboard",
    //   image: {
    //     inactive: "assets/img/account-menu-1.png",
    //     active: "assets/img/account-menu-1-active.png",
    //   },
    // },
    // {
    //   link: "/account/customer-news",
    //   text: "Nachrichten",
    //   image: {
    //     inactive: "assets/img/account-menu-2.png",
    //     active: "assets/img/account-menu-2-active.png",
    //   },
    // },
    {
      link: "/account/customer-lead",
      text: "Meine Anfragen",
      image: {
        inactive: "assets/img/account-menu-3.png",
        active: "assets/img/account-menu-3-active.png",
      },
    },
    // {
    //   link: "/account/customer-shop",
    //   text: "Online Shop",
    //   image: {
    //     inactive: "assets/img/account-menu-4.png",
    //     active: "assets/img/account-menu-4-active.png",
    //   },
    // },
    // {
    //   link: "/account/customer-favorite",
    //   text: "Favoriten",
    //   image: {
    //     inactive: "assets/img/account-menu-5.png",
    //     active: "assets/img/account-menu-5-active.png",
    //   },
    // },
    {
      link: "/account/customer-profile",
      text: "Mein Profil",
      image: {
        inactive: "assets/img/account-menu-6.png",
        active: "assets/img/account-menu-6-active.png",
      },
    },
    {
      link: "/account/customer-document",
      text: "Meine Dokumente",
      image: {
        inactive: "assets/img/account-menu-7.png",
        active: "assets/img/account-menu-7-active.png",
      },
    },
    {
      link: "/account/customer-financing",
      text: "Finanzierung anfragen",
      image: {
        inactive: "assets/img/account-menu-8.png",
        active: "assets/img/account-menu-8-active.png",
      },
    },
  ];

  menusP: Array<any> = [
    {
      link: "/account/provider-join",
      text: "Anbieter werden",
      image: {
        inactive: "assets/img/account-menu-join.png",
        active: "assets/img/account-menu-join-active.png",
      },
    },
    // {
    //   link: "/account/provider-dashboard",
    //   text: "Dashboard",
    //   image: {
    //     inactive: "assets/img/account-menu-1.png",
    //     active: "assets/img/account-menu-1-active.png",
    //   },
    // },
    // {
    //   link: "/account/provider-news",
    //   text: "Nachrichten",
    //   image: {
    //     inactive: "assets/img/account-menu-2.png",
    //     active: "assets/img/account-menu-2-active.png",
    //   },
    // },
    {
      link: "/account/provider-lead",
      text: "Meine Anfragen",
      image: {
        inactive: "assets/img/account-menu-3.png",
        active: "assets/img/account-menu-3-active.png",
      },
    },
    // {
    //   link: "/account/provider-shop",
    //   text: "Online Shop",
    //   image: {
    //     inactive: "assets/img/account-menu-4.png",
    //     active: "assets/img/account-menu-4-active.png",
    //   },
    // },
    // {
    //   link: "/account/provider-favorite",
    //   text: "Favoriten",
    //   image: {
    //     inactive: "assets/img/account-menu-5.png",
    //     active: "assets/img/account-menu-5-active.png",
    //   },
    // },
    {
      link: "/account/provider-profile",
      text: "Mein Profil",
      image: {
        inactive: "assets/img/account-menu-6.png",
        active: "assets/img/account-menu-6-active.png",
      },
    },
    {
      link: "/account/provider-document",
      text: "Meine Dokumente",
      image: {
        inactive: "assets/img/account-menu-7.png",
        active: "assets/img/account-menu-7-active.png",
      },
    },
    {
      link: "/account/provider-financing",
      text: "Finanzierung anfragen",
      image: {
        inactive: "assets/img/account-menu-8.png",
        active: "assets/img/account-menu-8-active.png",
      },
    },
  ];

  menus: Array<any> = [];

  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    if (this.role == "customer") {
      this.menus = this.menusC;
      this.apiService.getProfileCustomer().subscribe(
        (res) => {
          var customer = res["data"];
          this.avatar = customer.avatar;
        },
        (err) => {
          console.log(err);
        }
      );
    } else if (this.role == "provider") {
      this.menus = this.menusP;
      this.apiService.getProfileProvider().subscribe(
        (res) => {
          var provider = res["data"];
          this.avatar = provider.company_logo;
        },
        (err) => {
          console.log(err);
        }
      );
      if (this.subscribed) this.menus.shift();
      else this.menus.splice(1);
    }
  }

  logout() {
    this.authService.logout();
  }
}
