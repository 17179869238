import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category1",
  templateUrl: "./category1.component.html",
  styleUrls: ["./category1.component.scss"],
})
export class Category1Component implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}
  register() {
    this.router.navigateByUrl("/register-customer");
  }
}
