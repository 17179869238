import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NotifierService } from "angular-notifier";

import { ApiService } from "../../../../_services/api.service";
import { UtilService } from "../../../../_services/util.service";
import { UploadService } from "../../../../_services/upload.service";

@Component({
  selector: "app-customer-profile",
  templateUrl: "./customer-profile.component.html",
  styleUrls: ["./customer-profile.component.scss"],
})
export class CustomerProfileComponent implements OnInit {
  updateForm: FormGroup = null;
  submitted: boolean = false;

  avatar: any = "";

  constructor(
    private formBuilder: FormBuilder,
    private notifierService: NotifierService,
    private apiService: ApiService,
    private utilService: UtilService,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    this.updateForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        phone: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/
            ),
          ],
        ],
        password: [""],
        passwordConf: [""],
      },
      {
        validators: [this.utilService.mustMatch("password", "passwordConf")],
      }
    );

    this.apiService.getProfileCustomer().subscribe(
      (res) => {
        var customer = res["data"];

        this.updateF.firstName.setValue(customer.first_name);
        this.updateF.lastName.setValue(customer.last_name);
        this.updateF.email.setValue(customer.email);
        this.updateF.phone.setValue(customer.phone);
        this.updateF.password.setValue("");
        this.updateF.passwordConf.setValue("");
        this.avatar = customer.avatar;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  get updateF() {
    return this.updateForm.controls;
  }

  update() {
    this.submitted = true;

    var passwordLen = this.updateF.password.value.length;
    if (passwordLen > 0 && passwordLen < 6)
      this.updateF.password.setErrors({ minlength: true });

    if (this.updateForm.valid) {
      var avatar="";
      if(this.avatar!=null){
          avatar="/" + this.avatar.split("/").slice(3).join("/");
      }
      var req = {
        first_name: this.updateF.firstName.value,
        last_name: this.updateF.lastName.value,
        email: this.updateF.email.value,
        phone: this.updateF.phone.value,
        password: this.updateF.password.value,
        avatar: avatar,
      };

      this.apiService.postProfileCustomer(req).subscribe(
        (res) => {
          this.notifierService.notify(
            "success",
            "Profil erfolgreich aktualisiert."
          );
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    }
  }

  changeFile(event) {
    var reader = new FileReader(),
      file;

    if (event.target.files && event.target.files.length) {
      reader.onload = () => {
        file = event.target.files[0];

        this.uploadService.process(file, "avatar").subscribe(
          (res) => {
            this.avatar = res["data"];
          },
          (err) => {
            console.log(err);
          }
        );
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
