import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-payment",
  templateUrl: "./cart-payment.component.html",
  styleUrls: ["./cart-payment.component.scss"],
})
export class CartPaymentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
