import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Injectable } from "@angular/core";

import { StorageService } from "../_services/storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var token = this.storageService.get("token");

    if (token) return true;

    this.router.navigateByUrl("/login");

    return false;
  }
}
