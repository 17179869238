import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-category-contact",
  templateUrl: "./category-contact.component.html",
  styleUrls: ["./category-contact.component.scss"],
})
export class CategoryContactComponent implements OnInit {
  category: any = null;

  keyword: any = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.getCategory();
  }

  search(event) {
    this.keyword = event.keyword;

    this.getCategory();
  }

  getCategory() {
    var url = this.route.snapshot.params["url"];

    this.apiService.getWorldCategory(url, "", "", this.keyword).subscribe(
      (res) => {
        this.category = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
