import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { UtilService } from "../../../../../_services/util.service";

@Component({
  selector: "app-lead-single-date",
  templateUrl: "./lead-single-date.component.html",
  styleUrls: ["./lead-single-date.component.scss"],
})
export class LeadSingleDateComponent implements OnInit {
  @Input() question;
  @Input() savedAnswer;
  @Output("answeringE") answeringE: EventEmitter<any> = new EventEmitter();

  activeAnswer: any = null;
  today: any = null;

  constructor(private utilService: UtilService) {
    this.today = this.utilService.getToday();
  }

  ngOnInit() {
    if (this.savedAnswer) {
      this.activeAnswer = this.savedAnswer;
    } else {
      setTimeout(() => {
        this.answering(this.today);
      }, 10);
    }
  }

  answering(answer) {
    this.activeAnswer = answer;

    this.answeringE.emit({
      question: this.question.id,
      answer: this.activeAnswer,
      type: "single-date",
      position: this.question.position,
      next: this.question.answers[0].overwrite_next_question_pos,
    });
  }
}
