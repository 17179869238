import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { AuthService } from "../../../_services/auth.service";
import { StorageService } from "../../../_services/storage.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = null;
  submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notifierService: NotifierService,
    private authService: AuthService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  get loginF() {
    return this.loginForm.controls;
  }

  login() {
    this.submitted = true;

    if (this.loginForm.valid) {
      var req = {
        email: this.loginF["email"].value,
        password: this.loginF["password"].value,
      };

      this.authService.login(req).subscribe(
        (res) => {
          this.notifierService.notify("success", "Anmelden erfolgreich");

          setTimeout(() => {
            var token, role, name, subscribed, paymentwall;

            token = res["token"];

            if (res["role"] == "User") role = "customer";
            else if (res["role"] == "Company") role = "provider";

            name = res["name"];
            subscribed = res["subscribed"];
            paymentwall = res["paymentwall"];

            this.storageService.set("token", token);
            this.storageService.set("role", role);
            this.storageService.set("name", name);
            this.storageService.set("subscribed", subscribed);
            this.storageService.set("paymentwall", paymentwall);

            if (role == "customer")
              this.router.navigateByUrl("/world-category/leading-confirm");
            else if (role == "provider") {
              if (subscribed)
                this.router.navigateByUrl("/account/provider-lead");
              else this.router.navigateByUrl("/account/provider-join");
            }
          }, 1000);
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
    }
  }
}
