import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { StorageService } from "../../../_services/storage.service";

@Component({
  selector: "app-category-leading-confirm",
  templateUrl: "./category-leading-confirm.component.html",
  styleUrls: ["./category-leading-confirm.component.scss"],
})
export class CategoryLeadingConfirmComponent implements OnInit {
  constructor(private router: Router, private storageService: StorageService) {}

  ngOnInit() {
    var lead = this.storageService.get("lead");

    if (lead) {
    } else this.router.navigateByUrl("/account/customer-lead");
  }
}
