import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category3",
  templateUrl: "./category3.component.html",
  styleUrls: ["./category3.component.scss"],
})
export class Category3Component implements OnInit {
  @Input() categorys;

  constructor(private router: Router) {}

  ngOnInit() {}

  portalCategoryView(category) {
    this.router.navigateByUrl(`/portal-category/view/${category.url}`);
  }
}
