import { Routes } from "@angular/router";

import { CustomerLeadComponent } from "./customer/customer-lead/customer-lead.component";
import { CustomerLeadViewComponent } from "./customer/customer-lead-view/customer-lead-view.component";
import { CustomerProfileComponent } from "./customer/customer-profile/customer-profile.component";
/** coming soon **/
import { CustomerDashboardComponent } from "./customer/customer-dashboard/customer-dashboard.component";
import { CustomerNewsComponent } from "./customer/customer-news/customer-news.component";
import { CustomerShopComponent } from "./customer/customer-shop/customer-shop.component";
import { CustomerFavoriteComponent } from "./customer/customer-favorite/customer-favorite.component";
import { CustomerDocumentComponent } from "./customer/customer-document/customer-document.component";
import { CustomerFinancingComponent } from "./customer/customer-financing/customer-financing.component";

import { ProviderJoinComponent } from "./provider/provider-join/provider-join.component";
import { ProviderJoinFormComponent } from "./provider/provider-join-form/provider-join-form.component";
import { ProviderJoinFormingComponent } from "./provider/provider-join-forming/provider-join-forming.component";
import { ProviderLeadComponent } from "./provider/provider-lead/provider-lead.component";
import { ProviderLeadViewComponent } from "./provider/provider-lead-view/provider-lead-view.component";
import { ProviderProfileComponent } from "./provider/provider-profile/provider-profile.component";
/** coming soon **/
import { ProviderDashboardComponent } from "./provider/provider-dashboard/provider-dashboard.component";
import { ProviderNewsComponent } from "./provider/provider-news/provider-news.component";
import { ProviderShopComponent } from "./provider/provider-shop/provider-shop.component";
import { ProviderFavoriteComponent } from "./provider/provider-favorite/provider-favorite.component";
import { ProviderDocumentComponent } from "./provider/provider-document/provider-document.component";
import { ProviderFinancingComponent } from "./provider/provider-financing/provider-financing.component";

import { LayoutComponent } from "./_layout/layout.component";

import { AuthGuard } from "../../_guards/auth.guard";
import { RoleGuard } from "../../_guards/role.guard";
import { SubscribeGuard } from "../../_guards/subscribe.guard";
import { UnsubscribeGuard } from "../../_guards/unsubcribe.guard";

export const AccountRoutes: Routes = [
  {
    path: "account",
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {
        path: "customer-lead",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerLeadComponent,
      },
      {
        path: "customer-lead/view/:id",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerLeadViewComponent,
      },
      {
        path: "customer-profile",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerProfileComponent,
      },
      /** coming soon **/
      {
        path: "customer-dashboard",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerDashboardComponent,
      },
      {
        path: "customer-news",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerNewsComponent,
      },
      {
        path: "customer-shop",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerShopComponent,
      },
      {
        path: "customer-favorite",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerFavoriteComponent,
      },
      {
        path: "customer-document",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerDocumentComponent,
      },
      {
        path: "customer-financing",
        canActivate: [RoleGuard],
        data: { roles: ["customer"] },
        component: CustomerFinancingComponent,
      },

      {
        path: "provider-join",
        canActivate: [RoleGuard, UnsubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderJoinComponent,
      },
      {
        path: "provider-join/form",
        canActivate: [RoleGuard, UnsubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderJoinFormComponent,
      },
      {
        path: "provider-join/forming",
        canActivate: [RoleGuard, UnsubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderJoinFormingComponent,
      },
      {
        path: "provider-lead",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderLeadComponent,
      },
      {
        path: "provider-lead/view/:id",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderLeadViewComponent,
      },
      {
        path: "provider-profile",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderProfileComponent,
      },
      /** coming soon **/
      {
        path: "provider-dashboard",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderDashboardComponent,
      },
      {
        path: "provider-news",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderNewsComponent,
      },
      {
        path: "provider-shop",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderShopComponent,
      },
      {
        path: "provider-favorite",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderFavoriteComponent,
      },
      {
        path: "provider-document",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderDocumentComponent,
      },
      {
        path: "provider-financing",
        canActivate: [RoleGuard, SubscribeGuard],
        data: { roles: ["provider"] },
        component: ProviderFinancingComponent,
      },
    ],
  },
];
