import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { ApiService } from "../../../../_services/api.service";

@Component({
  selector: "app-customer-lead-view",
  templateUrl: "./customer-lead-view.component.html",
  styleUrls: ["./customer-lead-view.component.scss"],
})
export class CustomerLeadViewComponent implements OnInit {
  lead: any = null;
  activeIndex: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private apiService: ApiService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    var id = this.route.snapshot.params["id"];

    this.apiService.getLeadCusomter(id).subscribe(
      (res) => {
        this.lead = res["data"];

        for (var i = 0; i < this.lead.inquiries.length; i++) {
          if (this.lead.inquiries[i].status == "accepted") {
            this.activeIndex = i;
          } 
        }
          
        console.log(this.lead)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  inquiryCustomerStatus(index, status) {
    var req = {
        inquiry_id: this.lead.inquiries[index].id,
        status: status,
      },
      inquiry;

    this.apiService.postInquiryCustomerStatus(req).subscribe(
      (res) => {
        inquiry = res["data"];

        this.lead.inquiries[index] = inquiry;

        if (inquiry.status == "accepted") {
          this.activeIndex = index;
          this.notifierService.notify("success", "Inquiry accepted.");
        } else if (inquiry.status == "rejected") {
          this.activeIndex = null;
          this.notifierService.notify("success", "Inquiry rejected.");
        }
      },
      (err) => {
        console.log(err);
        this.notifierService.notify("error", err.error.message);
      }
    );
  }

  inquiryCustomerContact(index) {
    var req = {
      inquiry_id: this.lead.inquiries[index].id,
    };

    this.apiService.postInquiryCustomerContact(req).subscribe(
      (res) => {
        this.notifierService.notify("success", "Request financing success.");
      },
      (err) => {
        console.log(err);
        this.notifierService.notify("error", err.error.message);
      }
    );
  }
}
