import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";
import { StorageService } from "../../../_services/storage.service";

@Component({
  selector: "app-category-super",
  templateUrl: "./category-super.component.html",
  styleUrls: ["./category-super.component.scss"],
})
export class CategorySuperComponent implements OnInit {
  category: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private storageService: StorageService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    var url = this.route.snapshot.params["url"];

    this.storageService.set("portal-image", "");

    this.apiService.getPortalPost(url, "").subscribe(
      (res) => {
        this.category = res["data"];

        this.storageService.set("portal-image", this.category.image);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
