import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-lead-multiple-fixed",
  templateUrl: "./lead-multiple-fixed.component.html",
  styleUrls: ["./lead-multiple-fixed.component.scss"],
})
export class LeadMultipleFixedComponent implements OnInit {
  @Input() question;
  @Input() savedAnswer;
  @Output("answeringE") answeringE: EventEmitter<any> = new EventEmitter();

  activeAnswers: any = [];

  constructor() {}

  ngOnInit() {
    if (this.savedAnswer && this.savedAnswer.length)
      this.activeAnswers = this.savedAnswer;
    else this.activeAnswers = [];
  }

  answering(answer, checked) {
    if (checked) this.activeAnswers.push(answer);
    else {
      var index = this.activeAnswers.findIndex(
        (activeAnswer) => activeAnswer.id == answer.id
      );
      this.activeAnswers.splice(index, 1);
    }

    this.answeringE.emit({
      question: this.question.id,
      answer: this.activeAnswers,
      type: "multiple-fixed",
      position: this.question.position,
      next: this.question.answers[0].overwrite_next_question_pos,
    });
  }

  isChecked(answer) {
    var index = this.activeAnswers.findIndex(
      (activeAnswer) => activeAnswer.id == answer.id
    );
    return index > -1 ? true : false;
  }
}
