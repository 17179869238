import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-category-listing1",
  templateUrl: "./category-listing1.component.html",
  styleUrls: ["./category-listing1.component.scss"],
})
export class CategoryListing1Component implements OnInit {
  @Input() categorys;
  @Output("searchE") searchE: EventEmitter<any> = new EventEmitter();

  menus: Array<any> = [];

  constructor() {}

  ngOnInit() {
    this.menus = this.categorys.map((category) => {
      return { id: category.id, text: category.name };
    });
    this.menus.unshift({ id: "", text: "Kategorie" });
  }

  search(event) {
    this.searchE.emit(event);
  }
}
