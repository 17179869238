import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-provider2",
  templateUrl: "./provider2.component.html",
  styleUrls: ["./provider2.component.scss"],
})
export class Provider2Component implements OnInit {
  @Output("searchE") searchE: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  search(event) {
    this.searchE.emit(event);
  }
}
