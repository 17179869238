import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tos-provider1",
  templateUrl: "./tos-provider1.component.html",
  styleUrls: ["./tos-provider1.component.scss"],
})
export class TosProvider1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
