import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category-contact4",
  templateUrl: "./category-contact4.component.html",
  styleUrls: ["./category-contact4.component.scss"],
})
export class CategoryContact4Component implements OnInit {
  @Input() category;

  constructor(private router: Router) {}

  ngOnInit() {}

  worldCategoryContacting(url) {
    this.router.navigateByUrl(`/world-category/contacting/${url}`);
  }
}
