import { Component, OnChanges, Input } from "@angular/core";
import { Router } from "@angular/router";
import * as $ from "jquery";

@Component({
  selector: "app-category3",
  templateUrl: "./category3.component.html",
  styleUrls: ["./category3.component.scss"],
})
export class Category3Component implements OnChanges {
  @Input() worlds;

  index: any = 0;
  categorys: Array<any> = [];

  direct: any = "left";
  cycles: Array<any> = [];

  constructor(private router: Router) {}

  ngOnChanges() {
    this.index = 0;
    this.categorys = this.worlds[this.index]["categorys"];

    this.resize();
  }

  selectWorld(selected) {
    this.index = this.worlds.findIndex((world) => {
      return world.id == selected.id;
    });
    this.categorys = this.worlds[this.index]["categorys"];

    this.resize();
  }

  slideLeft() {
    this.direct = "left";
    $(".world-container").animate(
      { scrollLeft: $(".world-container").scrollLeft() - 400 },
      500
    );
  }

  slideRight() {
    this.direct = "right";
    $(".world-container").animate(
      { scrollLeft: $(".world-container").scrollLeft() + 400 },
      500
    );
  }

  resize(width = 0) {
    this.cycles = [];

    var cntPerView;

    if (width == 0) width = $("body").width();

    if (width >= 1600) cntPerView = 5;
    else if (width >= 1200 && width <= 1599) cntPerView = 4;
    else if (width >= 992 && width <= 1199) cntPerView = 4;
    else if (width >= 768 && width <= 991) cntPerView = 2;
    else if (width >= 576 && width <= 767) cntPerView = 2;
    else if (width >= 320 && width <= 575) cntPerView = 1;

    var cycle;

    for (var i = 0; i < this.categorys.length; i += cntPerView) {
      cycle = [];

      for (var j = i; j < i + cntPerView; j++) {
        if (this.categorys[j]) cycle.push(this.categorys[j]);
        else break;
      }

      this.cycles.push(cycle);
    }
  }

  worldCategoryPage(category) {
    if (category.type == "category")
      this.router.navigateByUrl(`/world-category/category/${category.url}`);
    else if (category.type == "content")
      this.router.navigateByUrl(`/world-category/content/${category.url}`);
    else if (category.type == "listing")
      this.router.navigateByUrl(`/world-category/listing/${category.url}`);
    else if (category.type == "contact")
      this.router.navigateByUrl(`/world-category/contact/${category.url}`);
    else if (category.type == "lead")
      this.router.navigateByUrl(`/world-category/lead/${category.url}`);
  }
}
