import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-portal-category",
  templateUrl: "./portal-category.component.html",
  styleUrls: ["./portal-category.component.scss"],
})
export class PortalCategoryComponent implements OnInit {
  @Input() categorys;

  constructor(private router: Router) {}

  ngOnInit() {}

  portalCategoryPage(category) {
    if (!category.is_content)
      this.router.navigateByUrl(`/portal-category/view/${category.url}`);
    else {
      if (category.is_image)
        this.router.navigateByUrl(`/portal-category/super/${category.url}`);
      else this.router.navigateByUrl(`/portal-category/simple/${category.url}`);
    }
  }
}
