import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-shop-view2",
  templateUrl: "./shop-view2.component.html",
  styleUrls: ["./shop-view2.component.scss"],
})
export class ShopView2Component implements OnInit {
  @Input() categorys;
  @Output("selectE") selectE: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  select(event) {
    this.selectE.emit(event);
  }
}
