import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-category-content",
  templateUrl: "./category-content.component.html",
  styleUrls: ["./category-content.component.scss"],
})
export class CategoryContentComponent implements OnInit {
  category: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    var url = this.route.snapshot.params["url"];

    this.apiService.getWorldCategory(url).subscribe(
      (res) => {
        this.category = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
