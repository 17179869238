import { Component, OnInit } from "@angular/core";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-faq-provider",
  templateUrl: "./faq-provider.component.html",
  styleUrls: ["./faq-provider.component.scss"],
})
export class FaqProviderComponent implements OnInit {
  faqProvider: any = null;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getSiteContents().subscribe(
      (res) => {
        this.faqProvider = res["data"]["faq_provider"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
