import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-search-super",
  templateUrl: "./search-super.component.html",
  styleUrls: ["./search-super.component.scss"],
})
export class SearchSuperComponent implements OnInit {
  @Input() menus;
  @Output("searchE") searchE: EventEmitter<any> = new EventEmitter();

  keyword: any = "";
  selected: any = 0;

  constructor() {}

  ngOnInit() {}

  keypress(event) {
    if ((event.keyCode ? event.keyCode : event.which) == 13) this.search();
  }

  selectMenu(index) {
    this.selected = index;

    this.search();
  }

  search() {
    this.searchE.emit({
      keyword: this.keyword,
      id: this.menus[this.selected].id,
    });
  }
}
