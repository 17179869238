import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category11",
  templateUrl: "./category11.component.html",
  styleUrls: ["./category11.component.scss"],
})
export class Category11Component implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  redirectToContact() {
    this.router.navigateByUrl("/contact");
  }
}
