import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import "rxjs/add/operator/switchMap";

import { ApiService } from "../../_services/api.service";

@Component({
  selector: "app-search-global",
  templateUrl: "./search-global.component.html",
  styleUrls: ["./search-global.component.scss"],
})
export class SearchGlobalComponent implements OnInit {
  searchField: FormControl = new FormControl();

  results: Array<any> = [];

  constructor(private router: Router, private apiService: ApiService) {}

  ngOnInit() {
    this.searchField.valueChanges
      .debounceTime(200)
      .distinctUntilChanged()
      .subscribe((keyword) => {
        if (keyword) {
          this.apiService.getSearch(keyword).subscribe((res) => {
            console.log(res["data"]);
            this.results = res["data"].map((result) => {
              var temp = {
                module: "",
                core: "",
                link: "",
              };

              if (result.route == "/world-category/category")
                temp = {
                  module: "Lebenswelten",
                  core: result.data.title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/world-category/content")
                temp = {
                  module: "Lebenswelten",
                  core: result.data.title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/world-category/listing")
                temp = {
                  module: "Lebenswelten",
                  core: result.data.title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/world-category/contact")
                temp = {
                  module: "Lebenswelten",
                  core: result.data.title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/world-category/lead")
                temp = {
                  module: "Lebenswelten",
                  core: result.data.title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/portal-category/view")
                temp = {
                  module: "Infoportal",
                  core: result.data.name || result.data.short_title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/portal-category/super")
                temp = {
                  module: "Infoportal",
                  core: result.data.short_title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/portal-category/simple")
                temp = {
                  module: "Infoportal",
                  core: result.data.short_title,
                  link: result.route + "/" + result.url,
                };
              else if (result.route == "/provider/view")
                temp = {
                  module: "Anbieterverzeichnis",
                  core: result.data.company_name,
                  link: result.route + "/" + result.url,
                };

              return temp;
            });
          });
        } else this.results = [];
      });
  }

  navigate(link) {
    this.router.navigateByUrl(link);
  }
}
