import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ApiService } from "../../../../_services/api.service";
import { UtilService } from "../../../../_services/util.service";

@Component({
  selector: "app-provider-lead",
  templateUrl: "./provider-lead.component.html",
  styleUrls: ["./provider-lead.component.scss"],
})
export class ProviderLeadComponent implements OnInit {
  leads: Array<any> = [];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.apiService.getLeadsProvider().subscribe(
      (res) => {
        this.leads = res["data"];

        this.leads = this.leads.map((lead) => {
          lead.date =
            this.utilService.getDateObj(lead.date).day +
            "." +
            this.utilService.getDateObj(lead.date).month +
            "." +
            this.utilService.getDateObj(lead.date).year;

          return lead;
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  accountProviderLeadView(lead) {
    this.router.navigateByUrl(`/account/provider-lead/view/${lead.id}`);
  }
}
