import { Component, OnInit } from "@angular/core";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-tos-customer",
  templateUrl: "./tos-customer.component.html",
  styleUrls: ["./tos-customer.component.scss"],
})
export class TosCustomerComponent implements OnInit {
  tosCustomer: any = null;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getSiteContents().subscribe(
      (res) => {
        this.tosCustomer = res["data"]["tos_customer"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
