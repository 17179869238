import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ApiService } from "../../../_services/api.service";
import { StorageService } from "../../../_services/storage.service";

@Component({
  selector: "app-category-lead",
  templateUrl: "./category-lead.component.html",
  styleUrls: ["./category-lead.component.scss"],
})
export class CategoryLeadComponent implements OnInit {
  category: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private storageService: StorageService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    var url = this.route.snapshot.params["url"];

    this.apiService.getWorldCategory(url).subscribe(
      (res) => {
        this.category = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );

    this.storageService.set("answers", []);
  }

  postLead(lead) {
    this.storageService.set("lead", lead);

    this.router.navigateByUrl("/world-category/leading-confirm");
  }
}
