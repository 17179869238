import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import { StorageService } from "../../../../../_services/storage.service";
import { ApiService } from "../../../../../_services/api.service";

@Component({
  selector: "app-product-view",
  templateUrl: "./product-view.component.html",
  styleUrls: ["./product-view.component.scss"],
})
export class ProductViewComponent implements OnInit {
  @Input() product;
  quantity: any = 1;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private notifierService: NotifierService,
    private apiService: ApiService
  ) {}

  ngOnInit() {}
  addToCart() {
    const cartId = this.storageService.get("cardId");
    this.apiService
      .addProductToCart(this.product.id, this.quantity, cartId)
      .subscribe(
        (res) => {
          this.storageService.set("cardId", res["data"].cart_key);
          this.notifierService.notify("success", "Inquiry success.");
        },
        (err) => {
          console.log(err);
          this.notifierService.notify("error", err.error.message);
        }
      );
  }
  changeQuantity(variant) {
    if (this.quantity + variant >= 1) {
      this.quantity = this.quantity + variant;
    }
  }
}
