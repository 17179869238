import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cart-order1",
  templateUrl: "./cart-order1.component.html",
  styleUrls: ["./cart-order1.component.scss"],
})
export class CartOrder1Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
