import { Component, OnInit, ElementRef } from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";

import { StorageService } from "../../_services/storage.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  private toggleButton: any;
  private sidebarVisible: boolean;

  background: any;
  placeholder: any;

  constructor(
    public location: Location,
    private element: ElementRef,
    private router: Router,
    private storageService: StorageService
  ) {
    this.sidebarVisible = false;

    this.router.events.subscribe((event) => {
      this.background = "assets/img/_bg_1.png";
      this.placeholder = "";

      if (event instanceof NavigationEnd) {
        if (new RegExp("/provider/view/").test(event.url))
          this.setPlaceHolder("provider");
        else if (new RegExp("/portal-category/super/").test(event.url))
          this.setPlaceHolder("portal");
      }
    });
  }

  ngOnInit() {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    html.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    // console.log(html);
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  setPlaceHolder(type) {
    setTimeout(() => {
      var image;

      if (type == "provider") image = this.storageService.get("provider-image");
      else if (type == "portal")
        image = this.storageService.get("portal-image");

      if (image) {
        this.background = "assets/img/_bg_2.png";
        this.placeholder = image;
      } else {
        this.background = "assets/img/_bg_1.png";
        this.placeholder = "";
      }
    }, 1000);
  }
}
