import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NotifierService } from "angular-notifier";
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from "../../../../_services/api.service";
import { UtilService } from "../../../../_services/util.service";

@Component({
  selector: "app-provider-lead-view",
  templateUrl: "./provider-lead-view.component.html",
  styleUrls: ["./provider-lead-view.component.scss"],
})
export class ProviderLeadViewComponent implements OnInit {
  lead: any = null;

  agreed: boolean = false;

  priceType: any = "";
  price1: any = 0;
  price2: any = 0;
  price: any = 0;
  dateVisit: any = "";
  date1: any = null;
  date2: any = null;
  file: any = null;
  description: any = "";
  hoveredDate: null;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private apiService: ApiService,
    private UtilService: UtilService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    var id = this.route.snapshot.params["id"];

    this.apiService.getLeadProvider(id).subscribe(
      (res) => {
        this.lead = res["data"];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.date1 && !this.date2) {
      this.date1 = date;
    } else if (this.date1 && !this.date2 && date.after(this.date1)) {
      this.date2 = date;
    } else {
      this.date2 = null;
      this.date1 = date;
    }
  }
  isHovered(date: NgbDate) {
    return this.date1 && !this.date2 && this.hoveredDate && date.after(this.date1) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.date2 && date.after(this.date1) && date.before(this.date2);
  }

  isRange(date: NgbDate) {
    return date.equals(this.date1) || (this.date2 && date.equals(this.date2)) || this.isInside(date) || this.isHovered(date);
  }

  changePriceType(priceType, isChecked) {
    if (isChecked) this.priceType = priceType;
    else this.priceType = "";
  }

  changeAgreed(isChecked) {
    this.agreed = isChecked;
  }

  changeFile(event) {
    var reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      reader.onload = () => {
        this.file = event.target.files[0];
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  submitInquiry() {
    if (this.agreed) {
      var req = new FormData(),
        passed = true;

      req.append("lead_id", this.lead.id);
      req.append("price_type", this.priceType);
      // req.append("description", this.description);
      // req.append(
      //   "date1",
      //   "2022/03/30"
      // );
      // req.append(
      //   "date2",
      //   "2022/03/30"
      // );
      req.append(
        "date1",
        `${this.UtilService.getDateObj(this.date1).day}/${this.UtilService.getDateObj(this.date1).month}/${this.UtilService.getDateObj(this.date1).year}`
      );
      if(this.date2 === null){
        this.notifierService.notify("error", "Please enter a valid date range");
        return 0
      }
      req.append(
        "date2",
        `${this.UtilService.getDateObj(this.date2).day}/${this.UtilService.getDateObj(this.date2).month}/${this.UtilService.getDateObj(this.date2).year}`
      );
      if(this.file) {
        req.append("file", this.file);
      }

      if (!this.description || !this.priceType )
        // passed = false;
        passed = true;

      if (this.priceType == "range") {
        req.append("price1", this.price1);
        req.append("price2", this.price2);
        req.append("date_visit", "");
      } else if (this.priceType == "fixed") {
        req.append("price1", this.price);
        req.append("price2", this.price);
        req.append("date_visit", "");
      } else if (this.priceType == "upload") {
        var dateVisit = this.dateVisit.split("-");

        req.append("price1", "0");
        req.append("price2", "0");
        req.append(
          "date_visit",
          dateVisit[2] + "/" + dateVisit[1] + "/" + dateVisit[0]
        );

        if (!this.dateVisit) passed = false;
      }

      if (passed) {
        this.apiService.postInquiryProvider(req).subscribe(
          (res) => {
            this.lead.inquiry = res["data"];
            this.notifierService.notify("success", "Inquiry success.");
          },
          (err) => {
            console.log(err);
            this.notifierService.notify("error", err.error.message);
          }
        );
      }
    }
  }
}
