import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-provider-view4",
  templateUrl: "./provider-view4.component.html",
  styleUrls: ["./provider-view4.component.scss"],
})
export class ProviderView4Component implements OnInit {
  @Input() provider;

  constructor() {}

  ngOnInit() {}

  navigate(url) {
    window.open(url);
  }
}
