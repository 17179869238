import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-customer-favorite",
  templateUrl: "./customer-favorite.component.html",
  styleUrls: ["./customer-favorite.component.scss"],
})
export class CustomerFavoriteComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
