import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-shop4",
  templateUrl: "./shop4.component.html",
  styleUrls: ["./shop4.component.scss"],
})
export class Shop4Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
