import { Routes } from "@angular/router";

import { CartComponent } from "./cart/cart.component";
import { CartAddressComponent } from "./cart-address/cart-address.component";
import { CartShippingComponent } from "./cart-shipping/cart-shipping.component";
import { CartPaymentComponent } from "./cart-payment/cart-payment.component";
import { CartOrderComponent } from "./cart-order/cart-order.component";

export const CartRoutes: Routes = [
  {
    path: "cart",
    children: [
      {
        path: "",
        component: CartComponent,
      },
      {
        path: "address",
        component: CartAddressComponent,
      },
      {
        path: "shipping",
        component: CartShippingComponent,
      },
      {
        path: "payment",
        component: CartPaymentComponent,
      },
      {
        path: "order",
        component: CartOrderComponent,
      },
    ],
  },
];
