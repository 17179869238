import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotifierModule } from "angular-notifier";

import { HelpRoutes } from "./help.routing";
import { VrModule } from "../../vr/vr.module";

import { FaqCustomerComponent } from "./faq-customer/faq-customer.component";
import { FaqCustomer1Component } from "./faq-customer/sections/faq-customer1/faq-customer1.component";
import { FaqCustomer2Component } from "./faq-customer/sections/faq-customer2/faq-customer2.component";

import { FaqProviderComponent } from "./faq-provider/faq-provider.component";
import { FaqProvider1Component } from "./faq-provider/sections/faq-provider1/faq-provider1.component";
import { FaqProvider2Component } from "./faq-provider/sections/faq-provider2/faq-provider2.component";

import { TosCustomerComponent } from "./tos-customer/tos-customer.component";
import { TosCustomer1Component } from "./tos-customer/sections/tos-customer1/tos-customer1.component";
import { TosCustomer2Component } from "./tos-customer/sections/tos-customer2/tos-customer2.component";

import { TosProviderComponent } from "./tos-provider/tos-provider.component";
import { TosProvider1Component } from "./tos-provider/sections/tos-provider1/tos-provider1.component";
import { TosProvider2Component } from "./tos-provider/sections/tos-provider2/tos-provider2.component";

import { PrivacyComponent } from "./privacy/privacy.component";
import { Privacy1Component } from "./privacy/sections/privacy1/privacy1.component";
import { Privacy2Component } from "./privacy/sections/privacy2/privacy2.component";

import { ImprintComponent } from "./imprint/imprint.component";
import { Imprint1Component } from "./imprint/sections/imprint1/imprint1.component";
import { Imprint2Component } from "./imprint/sections/imprint2/imprint2.component";

import { ContactComponent } from "./contact/contact.component";
import { Contact1Component } from "./contact/sections/contact1/contact1.component";
import { Contact2Component } from "./contact/sections/contact2/contact2.component";
import { Contact3Component } from "./contact/sections/contact3/contact3.component";

import { SupportComponent } from "./support/support.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(HelpRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule,
    VrModule,
  ],
  declarations: [
    FaqCustomerComponent,
    FaqCustomer1Component,
    FaqCustomer2Component,

    FaqProviderComponent,
    FaqProvider1Component,
    FaqProvider2Component,

    TosCustomerComponent,
    TosCustomer1Component,
    TosCustomer2Component,

    TosProviderComponent,
    TosProvider1Component,
    TosProvider2Component,

    PrivacyComponent,
    Privacy1Component,
    Privacy2Component,

    ImprintComponent,
    Imprint1Component,
    Imprint2Component,

    ContactComponent,
    Contact1Component,
    Contact2Component,
    Contact3Component,

    SupportComponent,
  ],
})
export class HelpModule {}
