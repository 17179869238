import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category-category3",
  templateUrl: "./category-category3.component.html",
  styleUrls: ["./category-category3.component.scss"],
})
export class CategoryCategory3Component implements OnChanges {
  @Input() category;
  @Output("directE") directE: EventEmitter<any> = new EventEmitter();

  sort = "";

  constructor(private router: Router) {}

  ngOnChanges() {}

  direct(sort) {
    this.sort = sort;

    this.directE.emit({
      sort: this.sort,
    });
  }

  worldCategoryPage(category) {
    if (category.type == "category")
      this.router.navigateByUrl(`/world-category/category/${category.url}`);
    else if (category.type == "content")
      this.router.navigateByUrl(`/world-category/content/${category.url}`);
    else if (category.type == "listing")
      this.router.navigateByUrl(`/world-category/listing/${category.url}`);
    else if (category.type == "contact")
      this.router.navigateByUrl(`/world-category/contact/${category.url}`);
    else if (category.type == "lead")
      this.router.navigateByUrl(`/world-category/lead/${category.url}`);
  }
}
