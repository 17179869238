import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-faq-provider2",
  templateUrl: "./faq-provider2.component.html",
  styleUrls: ["./faq-provider2.component.scss"],
})
export class FaqProvider2Component implements OnInit {
  @Input() faqProvider;

  constructor() {}

  ngOnInit() {}
}
