import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-shop-view4",
  templateUrl: "./shop-view4.component.html",
  styleUrls: ["./shop-view4.component.scss"],
})
export class ShopView4Component implements OnInit {
  @Input() rProducts;

  constructor() {}

  ngOnInit() {}
}
