import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TreeModule } from "@circlon/angular-tree-component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgmCoreModule } from "@agm/core";
import { NotifierModule } from "angular-notifier";

import { ProviderRoutes } from "./provider.routing";
import { VrModule } from "../../vr/vr.module";

import { ProviderComponent } from "./provider/provider.component";
import { Provider1Component } from "./provider/sections/provider1/provider1.component";
import { Provider2Component } from "./provider/sections/provider2/provider2.component";
import { Provider3Component } from "./provider/sections/provider3/provider3.component";
import { Provider4Component } from "./provider/sections/provider4/provider4.component";

import { ProviderViewComponent } from "./provider-view/provider-view.component";
import { ProviderView1Component } from "./provider-view/sections/provider-view1/provider-view1.component";
import { ProviderView2Component } from "./provider-view/sections/provider-view2/provider-view2.component";
import { ProviderView3Component } from "./provider-view/sections/provider-view3/provider-view3.component";
import { ProviderView4Component } from "./provider-view/sections/provider-view4/provider-view4.component";
import { ProviderView5Component } from "./provider-view/sections/provider-view5/provider-view5.component";
import { ProviderView6Component } from "./provider-view/sections/provider-view6/provider-view6.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ProviderRoutes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    NotifierModule,
    VrModule,
    TreeModule,
  ],
  declarations: [
    ProviderComponent,
    Provider1Component,
    Provider2Component,
    Provider3Component,
    Provider4Component,

    ProviderViewComponent,
    ProviderView1Component,
    ProviderView2Component,
    ProviderView3Component,
    ProviderView4Component,
    ProviderView5Component,
    ProviderView6Component,
  ],
})
export class ProviderModule {}
