import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  url: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {}

  public login(req) {
    this.url = this.apiUrl + "login";
    return this.http.post(this.url, req);
  }

  public logout() {
    this.storageService.remove("token");
    this.storageService.remove("role");
    this.storageService.remove("subscribed");
    this.router.navigateByUrl("/login");
  }

  public registerCustomer(req) {
    this.url = this.apiUrl + "register/customer";
    return this.http.post(this.url, req);
  }

  public registerProvider(req) {
    this.url = this.apiUrl + "register/provider";
    return this.http.post(this.url, req);
  }

  public verifyEmail(req) {
    this.url = this.apiUrl + "email/verify";
    return this.http.post(this.url, req);
  }

  public remindPassword(req) {
    this.url = this.apiUrl + "password/remind";
    return this.http.post(this.url, req);
  }

  public resetPassword(req) {
    this.url = this.apiUrl + "password/reset";
    return this.http.post(this.url, req);
  }
}
