import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as $ from "jquery";

@Component({
  selector: "app-provider-view2",
  templateUrl: "./provider-view2.component.html",
  styleUrls: ["./provider-view2.component.scss"],
})
export class ProviderView2Component implements OnInit {
  @Input() provider;

  cycles: Array<any> = [];
  image: any = 0;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.resize();
  }

  resize(width = 0) {
    this.cycles = [];

    var cntPerView;

    if (width == 0) width = $("body").width();

    if (width >= 1600) cntPerView = 4;
    else if (width >= 1200 && width <= 1599) cntPerView = 4;
    else if (width >= 992 && width <= 1199) cntPerView = 2;
    else if (width >= 768 && width <= 991) cntPerView = 2;
    else if (width >= 576 && width <= 767) cntPerView = 2;
    else if (width >= 320 && width <= 575) cntPerView = 1;

    var cycle;

    for (var i = 0; i < this.provider.company_images.length; i += cntPerView) {
      cycle = [];

      for (var j = i; j < i + cntPerView; j++) {
        if (this.provider.company_images[j])
          cycle.push(this.provider.company_images[j]);
        else break;
      }

      this.cycles.push(cycle);
    }
  }

  zoom(image, zoomModal) {
    this.image = image;
    this.modalService.open(zoomModal, { size: "xl" });
  }
}
