import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-category-contacting2",
  templateUrl: "./category-contacting2.component.html",
  styleUrls: ["./category-contacting2.component.scss"],
})
export class CategoryContacting2Component implements OnInit {
  @Input() industry;

  constructor(private router: Router) {}

  ngOnInit() {}

  providerView(url) {
    this.router.navigateByUrl(`/provider/view/${url}`);
  }
}
