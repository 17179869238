import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-register-provider-success",
  templateUrl: "./register-provider-success.component.html",
  styleUrls: ["./register-provider-success.component.scss"],
})
export class RegisterProviderSuccessComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  login() {
    this.router.navigateByUrl("/login");
  }
}
