import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  getToday() {
    return {
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("MM")),
      day: parseInt(moment().format("DD")),
    };
  }

  getDateObj(date) {
    return {
      year: moment(date).format("YYYY"),
      month: moment(date).format("MM"),
      day: moment(date).format("DD"),
    };
  }
}
