import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-category-listing",
  templateUrl: "./category-listing.component.html",
  styleUrls: ["./category-listing.component.scss"],
})
export class CategoryListingComponent implements OnInit {
  categorys: Array<any> = [];
  product: any = null;
  rProducts: Array<any> = [];

  constructor() {}

  ngOnInit() {
    this.categorys = [
      {
        id: 1,
        name: "Haus & Garten",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
      {
        id: 2,
        name: "Möbel",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
      {
        id: 3,
        name: "Elektronik & Computer",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
      {
        id: 4,
        name: "Baumarkt",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
      {
        id: 5,
        name: "Lebensmittel",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
      {
        id: 6,
        name: "Bekleidung",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
      {
        id: 7,
        name: "Sport & Freizeit",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
      {
        id: 8,
        name: "Sport & Freizeit",
        subcats: ["sub cat 1", "sub cat 2", "sub cat 3"],
      },
    ];

    this.product = {
      breadcrumbs: [
        {
          name: "Rasenmäher",
          url: "",
        },
      ],
      gallerys: [
        "assets/img/world-listing-product.png",
        "assets/img/world-listing-product.png",
        "assets/img/world-listing-product.png",
        "assets/img/world-listing-product.png",
        "assets/img/world-listing-product.png",
        "assets/img/world-listing-product.png",
      ],
      name: "Produkt XYZ",
      description:
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
      no: 123456,
      price: 160,
      variants: [
        {
          name: "variant 1",
          price: 140,
        },
        {
          name: "variant 2",
          price: 150,
        },
        {
          name: "variant 3",
          price: 160,
        },
      ],
    };

    this.rProducts = [
      {
        image: "assets/img/recommend-product-1.jpg",
        text: "Bosch Smart Home Rauchmelder weiß",
        price: 58.48,
      },
      {
        image: "assets/img/recommend-product-2.jpg",
        text: "Bosch Smart Home - Starter Set Heizung mit 4 Thermostaten",
        price: 299,
      },
      {
        image: "assets/img/recommend-product-3.jpg",
        text: "Smart Lock Fingerabdruckschloss",
        price: 93.84,
      },
      {
        image: "assets/img/recommend-product-4.jpg",
        text: "SENEC.HOME V2.1 Stromspeicher",
        price: 7128.1,
      },
      {
        image: "assets/img/recommend-product-5.jpg",
        text:
          "5000 Watt Solaranlage Hybridset einphasig mit 6 KWh Solarspeicher",
        price: 3828.34,
      },
      {
        image: "assets/img/recommend-product-6.jpg",
        text:
          "Heidelberg Wallbox Home Eco 11kW Ladestation für Elektro- und Hybridfahrzeuge",
        price: 364.61,
      },
      {
        image: "assets/img/recommend-product-7.jpg",
        text: "TAN Generator für Smart-TAN",
        price: 19.4,
      },
      {
        image: "assets/img/recommend-product-8.jpg",
        text: "Bonrollen / Thermopapier für EC-Cash, 50 Stk.",
        price: 23.0,
      },
      {
        image: "assets/img/recommend-product-9.jpg",
        text: "Verifone H5000 / Touchscreen",
        price: 6.9,
      },
      {
        image: "assets/img/recommend-product-10.png",
        text: "Olympia Münzrollen 3701 Farbig sortiert 8 Stück",
        price: 17.99,
      },
      {
        image: "assets/img/recommend-product-11.jpeg",
        text: "Drohnenaufnahmen",
        price: 99.0,
      },
      {
        image: "assets/img/recommend-product-12.jpg",
        text: "6 Bio-Sonneneier (L/M)",
        price: 2.99,
      },
      {
        image: "assets/img/recommend-product-13.jpg",
        text: "Bio-Band Nudeln",
        price: 2.99,
      },
      {
        image: "assets/img/recommend-product-14.jpg",
        text: "Grobe hausmacher Bratwürstchen",
        price: 0.69,
      },
    ];
  }

  search(event) {
    console.log(event);
  }

  select(event) {
    console.log(event);
  }
}
