import { Component, OnInit } from "@angular/core";

import { ApiService } from "../../../_services/api.service";

@Component({
  selector: "app-imprint",
  templateUrl: "./imprint.component.html",
  styleUrls: ["./imprint.component.scss"],
})
export class ImprintComponent implements OnInit {
  imprint: any = null;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getSiteContents().subscribe(
      (res) => {
        this.imprint = res["data"]["imprint"];
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
